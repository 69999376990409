import classNames from "classnames";
import { chain, size, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { selectSearch, toggleFacetTab } from "src/features/SearchSlice";
import Analytics from "src/utils/Analytics";
import { performSearch } from "src/utils/search";

const FacetFilterTab = ({ facetName }) => {
  const search = useSelector(selectSearch);
  const { panel, filters, hasDifferentFilters } = search;
  const isActive = panel.activeFacetTab === facetName;

  const dispatch = useDispatch();

  const getActiveFilterNamesByFacet = (facetName) => {
    const byFacetName = (filter) => filter.facet == facetName;
    const filterName = (filter) => filter.value;
    return chain(filters).filter(byFacetName).map(filterName).value();
  };

  const buildLabelForFacetTab = (facetName, filters) => {
    const alternativeFacetNames = {
      primary_collection: "collection",
      decade: "Decades",
    };

    const numberOfFilters = size(filters);

    if (numberOfFilters === 0) {
      return startCase(alternativeFacetNames[facetName] || facetName);
    }
    let label = chain(filters).first().truncate(25).value();
    label += facetName === "decade" ? "s" : "";

    // Add `+ X OTHERS` in the label if more than 1 filter is active
    // eg "National Library of New Zea... + 1 others"
    if (numberOfFilters > 1)
      label += ` + ${numberOfFilters - 1} ${
        numberOfFilters === 2 ? "OTHER" : "OTHERS"
      }`;

    return label;
  };

  const toggleFacetTabAndPerformSearch = (facetName) => {
    Analytics.event("click", "SearchFilterTabs", facetName);
    dispatch(toggleFacetTab({ facetName: facetName }));

    if (isActive && hasDifferentFilters) performSearch(dispatch, search);
  };

  const activeFiltersByFacet = getActiveFilterNamesByFacet(facetName);
  const facetTabClass = classNames({
    "search-panel__header__item": true,
    "search-panel__header__item--active": isActive,
    "search-panel__header__item--with-filters": size(activeFiltersByFacet) > 0,
    "search-panel__header__item--decade-filter":
      facetName === "decade" && size(activeFiltersByFacet) > 0,
  });

  return (
    <button
      className={facetTabClass}
      onClick={() => toggleFacetTabAndPerformSearch(facetName)}
      role="menuitem"
      aria-expanded="true"
    >
      {buildLabelForFacetTab(facetName, activeFiltersByFacet)}
      <i className="fa fa-chevron-down" aria-hidden="true"></i>
    </button>
  );
};

export default FacetFilterTab;
