import { includes, isEmpty, range } from "lodash";
import {
  setMobileFiltersState,
  toggleError,
  toggleFacetTab,
} from "../features/SearchSlice";
import generateSearchParams from "./generateSearchParams";

export const validDateRangeFilter = (dispatch, search) => {
  const dateRange = find(search.filters, (filter) => {
    return filter.facet == "date_range";
  });

  const validDates = range(1, new Date().getFullYear() + 1);
  let errors = [];

  if (dateRange) {
    const years = dateRange.value.split(" TO ");
    const fromYear = parseInt(years[0]);
    const toYear = parseInt(years[1]);

    if (!fromYear || !includes(validDates, fromYear)) {
      errors.push("from");
      dispatch(toggleError({ field: "from", value: true }));
    } else {
      dispatch(toggleError({ field: "from", value: false }));
    }

    if (!toYear || !includes(validDates, toYear)) {
      errors.push("to");
      dispatch(toggleError({ field: "to", value: true }));
    } else {
      dispatch(toggleError({ field: "to", value: false }));
    }

    if (fromYear > toYear) {
      errors.push("range");
      dispatch(toggleError({ field: "range", value: true }));
    } else {
      dispatch(toggleError({ field: "range", value: false }));
    }
  }

  return isEmpty(errors);
};

export const performSearch = (dispatch, search) => {
  const urlWithoutQueryParams = window.location.host + "/records?";
  const searchParams = generateSearchParams(
    search.query,
    search.filters,
    search.activeTab,
    1
  );

  if (validDateRangeFilter(dispatch, search)) {
    $(".spinner").show();
    // This uses the jquery param method to convert an object into properly
    // url encoded query parameters
    const newUrl = "http://" + urlWithoutQueryParams + $.param(searchParams);
    window.location.assign(newUrl);
  } else {
    if (search.mobileFiltersState == "closed") {
      if (search.panel.activeFacetTab != "date_range") {
        dispatch(toggleFacetTab({ facetName: "date_range" }));
      }
    } else {
      if (search.mobileFiltersState != "date_range") {
        dispatch(setMobileFiltersState("date_range"));
      }
    }
  }
};
