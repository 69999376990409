import { isEmpty, mixin } from "lodash";
import inflection, { singularize } from "lodash-inflection";
mixin(inflection);

export default function generateIconClass(category) {
  if (isEmpty(category)) category = ["Other"];

  let title = category.map((c) => {
    if (c !== "Data") {
      return c
        .split(" ")
        .map((word) => {
          return singularize(word);
        })
        .join(" ");
    }

    return c;
  });

  return title.join(" ");
}
