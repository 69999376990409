import MediaInRecordPage from "src/components/MediaInRecordPage";
import MoreInformation from "./MoreInformation";

import UnknownImage from "images/unknown.png";

const UnknownRights = (props) => {
  const { displayContentPartner, sourceUrl } = props;

  return (
    <>
      <MediaInRecordPage
        img_src={UnknownImage}
        img_alt="Unknown"
        title="Unknown"
      >
        <p>
          We haven't been able to determine the rights status of this item. It{" "}
          <b>may</b> be suitable for sharing, modification and commercial use,
          but you will need to{" "}
          <a href={sourceUrl} target="_blank" className="external-link">
            check with {displayContentPartner}
          </a>{" "}
          about its specific terms of use, and may need to undertake research to
          find the creator and publisher of each object to determine this.
        </p>
      </MediaInRecordPage>

      <MoreInformation {...props} />
    </>
  );
};

export default UnknownRights;
