import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "filters",
  initialState: {},
  reducers: {
    selectNewFacet: (state, action) => {
      state.selectedFacet = action.payload;
    },
    selectNewDateRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

const { actions, reducer } = filtersSlice;

export const selectFilters = (state) => state.filters;
export const getSelectedFacet = (state) => state.filters.selectedFacet;
export const getStartDate = (state) => state.filters.startDate;
export const getEndDate = (state) => state.filters.endDate;

export const { selectNewFacet, selectNewDateRange } = actions;

export default reducer;
