import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const emptySearchFacetsAdapter = createEntityAdapter();

const emptySearchFacetsSlice = createSlice({
  name: "emptySearchFacets",
  initialState: {},
  reducers: {},
});

export const {
  selectById: selectEmptySearchFacetById,
  selectIds: selectEmptySearchFacetIds,
  selectEntities: selectEmptySearchFacetEntities,
  selectAll: selectAllEmptySearchFacets,
  selectTotal: selectTotalEmptySearchFacets,
} = emptySearchFacetsAdapter.getSelectors(
  (state) => state.entities.emptySearchFacets
);

const { reducer } = emptySearchFacetsSlice;

export default reducer;
