import classNames from "classnames";
import { map } from "lodash";
import { useSelector } from "react-redux";

import RecordCard from "src/components/RecordCard/RecordCard";
import withSearchResults from "src/components/RecordCard/hoc/withSearchResults";

import { selectAddToStory } from "src/features/AddToStorySlice";
import { selectRecordById } from "src/features/RecordsSlice";
import { selectSearch } from "src/features/SearchSlice";

import { useLocalStorage } from "src/utils/useLocalStorage";

import Pagination from "src/components/SearchPage/components/Pagination";

const SearchResults = ({ redirectToRecord }) => {
  const { activeRecord } = useSelector(selectAddToStory);
  const { loading, layout } = useSelector(selectSearch);
  const results = useSelector((state) =>
    selectSearch(state).resultsIds.map((id) => selectRecordById(state, id))
  );

  const [storageLayout] = useLocalStorage("searchResultsLayout", "grid");
  const resultsLayout = layout || storageLayout;

  const WithSearchResults = withSearchResults(RecordCard);
  const cards = map(results, (r) => (
    <div className="cell masonry-card-layout__item" key={r.id}>
      <WithSearchResults
        {...r}
        layout={resultsLayout}
        redirectToRecord={redirectToRecord}
        active={activeRecord}
      />
    </div>
  ));

  const spinner = (
    <div className="spinner spinner--load-more" aria-live="assertive">
      <i
        className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
        aria-hidden="true"
      ></i>
      <p>Loading</p>
    </div>
  );

  return (
    <div className="grid-container">
      <div
        className={classNames("grid-x", {
          "small-up-1 medium-up-2 large-up-4": resultsLayout !== "list",
          "small-12": resultsLayout === "list",
        })}
        options={{ gutter: 0, transitionDuration: 0 }}
      >
        {cards}
      </div>

      <Pagination />

      {loading && spinner}
    </div>
  );
};

export default SearchResults;
