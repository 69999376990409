import StoryContainer from "../../story/StoryContainer";
import { EditStory } from "../../pages";
import StoryLayout from "../../layouts/ShowStory";

import {
  selectBlocks,
  selectStoryId,
  fetchStory,
} from "src/features/ItemsSlice";
import { selectStoryById } from "src/features/StoriesSlice";
import { selectUser } from "src/features/UserSlice";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const Show = (props) => {
  const storyId = useParams().id;
  const dispatch = useDispatch();

  const blocks = useSelector(selectBlocks);
  const blocksStoryId = useSelector(selectStoryId);
  const metadata = useSelector((state) => selectStoryById(state, storyId));
  const user = useSelector(selectUser);
  const blocksAreLoaded = blocksStoryId === storyId;

  useEffect(() => {
    // this check is for the navigation from /records and /stories
    if (!blocksAreLoaded) {
      dispatch(fetchStory(storyId));
    }
  }, [blocksAreLoaded, dispatch, storyId]);

  // TODO work out how to go back to the story here.
  // const { query, filters, activeTab } = useSelector(state => state.search);
  // const searchParams = generateSearchParams(
  //   query,
  //   filters,
  //   activeTab
  // );

  const userOwnsStory = metadata?.userId === user.apiId;

  return (
    <>
      {blocksAreLoaded && userOwnsStory && <EditStory id={storyId} />}

      {blocksAreLoaded && !userOwnsStory && (
        <StoryLayout blocks={blocks} metadata={metadata} user={user}>
          <StoryContainer dispatch={dispatch} id={storyId} {...props} />
        </StoryLayout>
      )}
    </>
  );
};

export default Show;
