import { useState } from "react";
import { useSelector } from "react-redux";

import { selectSearch } from "src/features/SearchSlice";

import generateSearchParams from "src/utils/generateSearchParams";
import SearchRow from "./SearchRow";

const searchHref = (filters, page) => {
  const queryFilter = filters.find((filter) => filter.facet === "");
  const activeTab = filters.find((filter) => filter.facet === "category");

  const params = generateSearchParams(
    queryFilter ? queryFilter.value : "",
    filters.filter(
      (filter) => filter.facet !== "" && filter.facet !== "category"
    ),
    activeTab ? activeTab.value : "All",
    page
  );

  return `/records?${$.param(params)}`;
};

const AdvancedSearch = () => {
  const { query, activeTab, filters, page } = useSelector(selectSearch);
  const initialRows = [
    {
      include: true,
      exactMatch: true,
      terms: query,
      field: "",
    },
  ];

  if (activeTab !== "All") {
    initialRows.push({
      include: true,
      exactMatch: true,
      terms: activeTab,
      field: "category",
    });
  }
  filters.forEach((filter) => {
    initialRows.push({
      include: true,
      exactMatch: true,
      terms: filter.value,
      field: filter.facet,
    });
  });

  const [rows, setRows] = useState(initialRows);

  const handleAddRow = (e) => {
    setRows([
      ...rows,
      { include: true, exactMatch: true, terms: "", field: "" },
    ]);
  };

  const handleChange = (key, fields) => {
    setRows(
      rows.map((row, index) => {
        return key === index ? fields : row;
      })
    );
  };

  const handleDeleteRow = (key) => {
    setRows(rows.filter((_row, index) => key !== index));
  };

  const handleSubmit = () => {
    window.location.assign(
      window.location.protocol +
        "//" +
        window.location.host +
        searchHref(buildFilters(), page)
    );
  };

  const buildFilters = () => {
    return rows.map((row) => {
      return { facet: row.field, value: row.terms };
    });
  };

  return (
    <form className="grid-container" onSubmit={handleSubmit}>
      <div className="grid-x align-middle">
        <div className="cell auto">
          <h1 className="h2 margin-0 margin-bottom-1">Find records that...</h1>
        </div>
        <div className="cell shrink">
          <a href="/help/how-to-search-digitalnz">
            Advanced search help{" "}
            <span aria-hidden="true" className="fa fa-question-circle-o"></span>
          </a>
        </div>
      </div>
      {rows.map((row, index) => (
        <SearchRow
          key={index}
          index={index}
          row={row}
          handleChange={handleChange}
          handleDeleteRow={handleDeleteRow}
          deletable={rows.length > 1}
        />
      ))}

      <div className="grid-x align-justify margin-bottom-2">
        <button className="button hollow" onClick={handleAddRow} type="button">
          Add row <span aria-hidden="true" className="fa fa-plus"></span>
        </button>
        <a
          className="button"
          href={searchHref(buildFilters(), page)}
          onClick={handleSubmit}
          type="submit"
        >
          Search
        </a>
      </div>
    </form>
  );
};

export default AdvancedSearch;
