import { useSelector } from "react-redux";

import { get, map, sum, toPairs } from "lodash";
import MetricBox from "./MetricBox";

import { getSelectedFacet } from "src/features/Dashboard/FiltersSlice";
import { selectedTotalSets } from "src/features/Dashboard/GlobalMetricsSlice";
import {
  selectedMetrics,
  selectedTotalItems,
} from "src/features/Dashboard/MetricsSlice";

const formatAsOfDescription = (description) => {
  return `${description} as of yesterday`;
};

const IndexPage = () => {
  const metrics = useSelector(selectedMetrics);
  const totalStories = useSelector(selectedTotalSets);
  const selectedFacet = useSelector(getSelectedFacet);
  const totalItems = useSelector(selectedTotalItems);

  const metricsBoxData = {
    whats_new: {
      title: "What's new",
      description: "New items",
      number: sum(map(metrics.records, (m) => m.total_new_records)),
      tooltip:
        "The number of new items added to DigitalNZ over the specified timeframe.",
    },
    items: {
      title: "Items",
      description: formatAsOfDescription("Total items"),
      number: totalItems,
      tooltip:
        "The total number of items in the complete DigitalNZ data service. This total includes items that are not discoverable through www.digitalnz.org.",
    },
    interactions: {
      title: "Interactions",
      description: "Total item activity",
      number: sum(
        map(metrics.views, (viewData) => {
          return (
            get(viewData, "records_added_to_user_sets", 0) +
            get(viewData, "records_added_to_user_stories", 0) +
            get(viewData, "total_source_clickthroughs", 0)
          );
        })
      ),
      tooltip:
        "The number of times a user has clicked-through to the content source, or added an item to a story.",
    },
    views: {
      title: "Views and Impressions",
      description:
        "Total items appearing in search results, in viewed Stories, or viewed on full details pages",
      number: sum(
        map(metrics.views, (viewData) => {
          return (
            get(viewData, "searches", 0) +
            get(viewData, "record_page_views", 0) +
            get(viewData, "user_set_views", 0) +
            get(viewData, "user_story_views", 0)
          );
        })
      ),
      tooltip:
        "A combined count of how many times an item has been viewed on its full details page, appeared in any search results,  or displayed in a Story.",
    },
    sets: {
      title: "Stories",
      description: formatAsOfDescription("Total public Stories"),
      number: totalStories,
      tooltip:
        "The total number of public Stories created. Stories with a status of hidden or private are not reflected in this number.",
    },
  };

  if (selectedFacet !== "all") metricsBoxData.sets.notAvailable = true;

  const metricBoxes = map(toPairs(metricsBoxData), (pair) => {
    const [id, metricData] = pair;
    return (
      <div className="cell small-12 medium-6 large-4" key={id}>
        <MetricBox id={id + "_metric"} {...metricData} />
      </div>
    );
  });

  return <div className="grid-x grid-margin-x">{metricBoxes}</div>;
};

export default IndexPage;
