import $ from "jquery";
import { Component, createRef } from "react";
import Analytics from "src/utils/Analytics";
import generateSearchParams from "src/utils/generateSearchParams";

import { bindAll } from "lodash";

class CategoryTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.tabRef = createRef();
    bindAll(this, "categoryLink");
  }

  componentDidMount() {
    if (this.props.categoryName === this.props.activeCategory) {
      this.tabRef.current.focus({ preventScroll: true });
    }
  }

  categoryLink(categoryName, searchValue) {
    const searchParams = generateSearchParams(
      searchValue,
      this.props.filters,
      categoryName,
      1
    );
    return `/records?${$.param(searchParams)}`;
  }

  render() {
    const { classNames, activeCategory, categoryName, searchValue } =
      this.props;
    const activeTag =
      categoryName === activeCategory ? "search-tabs__filter--active" : "";

    const count = this.props.count;

    const disabledTag = count === "0" ? "search-tabs__filter--disabled" : "";

    return (
      <li
        className={activeTag + " " + classNames + " " + disabledTag}
        role="presentation"
      >
        <a
          href={this.categoryLink(categoryName, searchValue)}
          aria-label={categoryName + " " + count + " records."}
          role="menuitem"
          ref={this.tabRef}
          tabIndex="0"
          ga-action="click"
          ga-category="SearchCategoryTabs"
          ga-label={categoryName}
          onClick={Analytics.onClick}
        >
          {categoryName}
          <div className="search-tabs__filter__count">{count}</div>
        </a>
      </li>
    );
  }
}

export default CategoryTab;
