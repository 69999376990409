import classNames from "classnames";
import { useState } from "react";

const MediaInRecordPage = ({ img_src, img_alt, title, children }) => {
  let [selected, setSelected] = useState(false);

  const toggleInformationDropdownClick = () => setSelected(!selected);

  const toggleInformationDropdownKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelected(!selected);
    }
  };

  return (
    <>
      <div
        className="grid-x record-media"
        onClick={toggleInformationDropdownClick}
      >
        <div className="cell shrink record-media__media-container">
          <img src={img_src} alt={img_alt} className="record-media__media" />
        </div>

        <div className="cell auto">
          <h4 className="record-media__header margin-top-0 margin-bottom-0">
            {title}
          </h4>
          <div className="show-for-medium record-media__content">
            {children}
          </div>
        </div>

        <div className="cell show-for-small-only shrink">
          <i
            className={"fa " + (selected ? "fa-minus" : "fa-plus")}
            role="button"
            tabIndex="0"
            onKeyDown={toggleInformationDropdownKeyDown}
          ></i>
        </div>
      </div>

      <div className="show-for-small-only">
        <div className={classNames("grid-x", { hide: !selected })}>
          <div className="cell record-media__content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default MediaInRecordPage;
