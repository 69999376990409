import { bindAll } from "lodash";
import { Component } from "react";
import { request } from "src/utils/request";

class DeleteControls extends Component {
  constructor(props) {
    super(props);

    bindAll(this, "deleteStory", "setDeleteStory");
  }

  deleteStory() {
    const url = `/api/stories/${this.props.id}`;
    request({ url: url, options: { method: "delete" } }).then((json) => {
      if (json.status === 200) window.location.reload();
    });
  }

  setDeleteStory() {
    const deleteConfirmation = confirm("Do you want to delete this story?");
    deleteConfirmation ? this.deleteStory() : null;
  }

  render() {
    const { numberOfItems } = this.props;

    return (
      <div className="record-card__footer__delete-controls">
        <p className="record-card__footer__delete-controls__number-of-items">
          {numberOfItems} items
        </p>
        <button
          className="record-card__footer__delete-controls__icon"
          onClick={this.setDeleteStory}
          aria-label="Delete the story"
        >
          <i className="fa fa-trash-o" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

export default DeleteControls;
