import classnames from "classnames";
import SocialMediaLinks from "src/components/SocialMediaLinks";
import formatDate from "src/utils/formatDate";
import ReportItem from "./ReportItem.jsx";

const RecordSideInformation = (props) => {
  return (
    <>
      <div className={classnames("record-side-info", props.modifier)}>
        <h3 className="record-side-info__title">
          What can I do with this item?
        </h3>
        <p className="record-side-info__info">
          Check copyright status and what you can do with this item
        </p>
        <a className="record-side-info__link" href="#copyright">
          <i className="dnz-icon dnz-icon--red dnz-icon--arrow-down"></i>Check
          information
        </a>
      </div>

      {!props.record.belongsToUser && (
        <div className={classnames("record-side-info", props.modifier)}>
          <h3 className="record-side-info__title">Report this item</h3>
          <p className="record-side-info__info">
            If you believe this item breaches our terms of use please report
            this item
          </p>
          <ReportItem
            {...props.record}
            recaptchaSiteKey={props.recaptchaSiteKey}
          />
        </div>
      )}

      <div
        className={classnames(
          "record-side-info",
          "record-side-info--callout",
          props.modifier
        )}
      >
        <p>
          <a href="/">DigitalNZ</a> brings together more than 30 million items
          from institutions so that they are easy to find and use.{" "}
          {props.record.displayCollection !== "User Uploaded Content" &&
            "This information is the best information we could find on this item. "}
          This item was added on {formatDate(props.record.createdAt)}, and
          updated {formatDate(props.record.updatedAt)}.<br />
          <a href="/about">Learn more</a> about how we work.
        </p>
      </div>

      <div
        className={classnames("record-side-info d-print-none", props.modifier)}
      >
        <h3 className="record-side-info__title">Share</h3>

        <SocialMediaLinks id={props.record.id} />
      </div>
    </>
  );
};

export default RecordSideInformation;
