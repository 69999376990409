// TODO  This component does not need to access redux - ie mapstatetoprops, because it's parent (show.js)
// can pass all the required props now.

import { useDispatch, useSelector } from "react-redux";
import StoryContainer from "../../story/StoryContainer";
import Header from "./header/Header";
import BaseLayout from "../../layouts/Base";
import StoryError from "./StoryError";
import { filter } from "lodash";

import { selectStoryById, updateStory } from "src/features/StoriesSlice";
import { selectBlocks } from "src/features/ItemsSlice";
import { selectUser } from "src/features/UserSlice";

// Return the number of text blocks that are currently
// In the story
function countStoryTextBlocks(state) {
  return filter(selectBlocks(state), (content) => {
    return content.type === "text";
  }).length;
}

const Page = ({ id }) => {
  const dispatch = useDispatch();
  const metadata = useSelector((state) => selectStoryById(state, id));
  const user = useSelector(selectUser);
  const storyTextBlockCount = useSelector(countStoryTextBlocks);

  const boundStoryMetadataUpdate = (fieldName, value) => {
    dispatch(updateStory({ storyId: id, payload: { [fieldName]: value } }));
  };

  const headerProps = {
    ...metadata,
    storyTextBlockCount,
  };

  const header = (
    <Header
      {...headerProps}
      storyMetadataUpdateCb={boundStoryMetadataUpdate}
      user={user}
    />
  );

  return (
    <BaseLayout Header={header} id={metadata.id}>
      <StoryError />
      <StoryContainer dispatch={dispatch} id={id} editable />
    </BaseLayout>
  );
};

export default Page;
