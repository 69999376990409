import { Component } from "react";

import { cancelEditCaptionAndNotes } from "src/features/EditStorySlice";
import { blockUpdate } from "src/features/ItemsSlice";

import { assign, bindAll, concat, includes, remove, slice } from "lodash";
import CloseOnEscape from "react-close-on-escape";
import InputCounter from "src/utils/inputCounter";

const CATEGORY_OPTIONS = [
  "",
  "Images",
  "Audio",
  "Videos",
  "Articles",
  "Websites",
  "Books",
  "Newspapers",
  "Other",
  "Manuscripts",
  "Research papers",
  "Data",
  "Archives",
  "Guides",
];

class EditDnzMetaForm extends Component {
  constructor(props) {
    super(props);
    const { meta } = props;
    let title = meta.title ? meta.title : props.title;
    title = title === props.title ? "" : title;

    this.state = assign(
      {},
      meta,
      { caption: meta.caption ? meta.caption : "" },
      { title: title },
      { category: meta.category }
    );

    bindAll(this, "handleChange", "handleSubmit", "handleCancel");
  }

  componentDidMount() {
    this.focusedElement.focus();
    InputCounter.init();
  }

  handleChange(e, key) {
    let tags = slice(this.state.tags);
    switch (key) {
      case "isCover":
        this.setState({ isCover: e.target.checked });
        break;
      case "caption":
        this.setState({ caption: e.target.value });
        break;
      case "title":
        this.setState({ title: e.target.value });
        break;
      case "category":
        this.setState({ category: e.target.value });
        break;
      case "ssTePrimarySource":
        if (e.target.checked) tags = concat(tags, "s2s_te_primary_source");
        else remove(tags, (i) => i === "s2s_te_primary_source");
        this.setState({ tags });
        break;
      default:
        break;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { title } = this.props;
    if (title === this.state.title) {
      this.setState({ title: "" }, this.submit);
    } else {
      this.submit();
    }

    this.props.doneEditing();
    event.preventDefault();
  }

  handleCancel() {
    this.props.dispatch(cancelEditCaptionAndNotes());
    this.props.doneEditing();
  }

  submit() {
    this.props.dispatch(
      blockUpdate({
        storyId: this.props.storyId,
        blockId: this.props.id,
        meta: this.state,
      })
    );
  }

  render() {
    const { isCover, caption, title, tags, category } = this.state;
    const ssTePrimarySource = includes(tags, "s2s_te_primary_source");
    const { user } = this.props;

    const isSchoolAdmin = includes(user.roles, "school_admin") && user.loggedIn;

    const categoriesDropdown = CATEGORY_OPTIONS.map((cat) => {
      return (
        <option key={cat} value={cat}>
          {cat ? cat : "Default category"}
        </option>
      );
    });

    const s2sBoxes = isSchoolAdmin ? (
      <div className="cell">
        <label>Category</label>
        <select
          name="category"
          value={category}
          onChange={(e) => this.handleChange(e, "category")}
        >
          {categoriesDropdown}
        </select>
        <label>
          <input
            type="checkbox"
            checked={ssTePrimarySource}
            onChange={(e) => this.handleChange(e, "ssTePrimarySource")}
          />
          Primary source
        </label>
      </div>
    ) : null;

    const editForm = (
      <form
        onSubmit={this.handleSubmit}
        className="cell"
        role="dialog"
        aria-label="Edit title and caption for this item"
        aria-describedby="story-title-label story-caption-label"
      >
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell">
              <label aria-label="Story title" htmlFor="title">
                Caption <span className="input-counter">120</span>
              </label>
              <input
                id="title"
                name="title"
                className=""
                type="text"
                maxLength="120"
                value={title}
                onChange={(e) => this.handleChange(e, "title")}
                ref={(input) => {
                  this.focusedElement = input;
                }}
                aria-labelledby="story-title-label"
              />
            </div>

            <div className="cell">
              <label aria-label="Story caption" htmlFor="description">
                Notes{" "}
                <span className="input-counter">
                  {isSchoolAdmin ? 700 : 120}
                </span>
              </label>
              <input
                id="description"
                name="description"
                className=""
                type="text"
                maxLength={isSchoolAdmin ? "700" : "120"}
                value={caption}
                onChange={(e) => this.handleChange(e, "caption")}
                ref={(input) => {
                  this.captionInput = input;
                }}
                aria-labelledby="story-caption-label"
              />
            </div>

            {isSchoolAdmin && s2sBoxes}

            {this.props.imageUrl && (
              <div className="cell">
                <input
                  type="checkbox"
                  aria-checked={Boolean(isCover)}
                  checked={Boolean(isCover)}
                  onChange={(e) => this.handleChange(e, "isCover")}
                  aria-labelledby="story-coverthumb-label"
                />
                <label
                  id="story-coverthumb-label"
                  aria-label="Use as cover image for this story"
                >
                  Use as cover image for this story
                </label>
              </div>
            )}

            <div className="cell text-right margin-bottom-1">
              <button
                className="button clear"
                type="button"
                onClick={this.handleCancel}
                aria-label="cancel and close popup"
              >
                Cancel
              </button>

              <input
                type="submit"
                value="Save"
                className="button"
                aria-label="save and close popup"
              />
            </div>
          </div>
        </div>
      </form>
    );

    return (
      <CloseOnEscape onEscape={this.handleCancel}>{editForm}</CloseOnEscape>
    );
  }
}

export default EditDnzMetaForm;
