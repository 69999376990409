/* eslint-disable no-process-env, no-undef */

import { useState, useEffect } from "react";

const SocialMediaLinks = ({ id }) => {
  const [urls, setUrls] = useState({ twitter: "#", facebook: "#" });

  useEffect(
    function () {
      setUrls({
        facebook: `https://www.facebook.com/sharer/sharer.php?${facebookParams()}`,
        twitter: `https://twitter.com/intent/tweet?${twitterParams()}`,
      });
    },
    [id]
  );

  const twitterParams = () =>
    new URLSearchParams({
      original_referer: "https://digitalnz.org/",
      text: window.document.title,
      url: window.location.href.replace(/#.*/, ""),
    });

  const facebookParams = () =>
    new URLSearchParams({
      u: window.location.href.replace(/#.*/, ""),
    });

  function openFacebook(event) {
    event.preventDefault();
    window.open(
      event.target.href,
      "facebook-share-dialog",
      "width=626,height=436"
    );
  }

  return (
    <ul className="social-media">
      <li className="social-media__item">
        <a
          className="share-btn share-btn--facebook"
          href={urls["facebook"]}
          onClick={openFacebook}
          target="_blank"
          title="Share on Facebook"
        ></a>
      </li>
      <li className="social-media__item">
        <a
          className="share-btn share-btn--twitter"
          href={urls["twitter"]}
          target="_blank"
          title="Share on Twitter"
        ></a>
      </li>
    </ul>
  );
};

export default SocialMediaLinks;
