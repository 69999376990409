import values from "lodash/values";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";

// text (str), type (str), filters (arr)
export default function generateTitleText(text, type, filters, tab) {
  text = unescapeXMLCharacters(text);

  if (type === "RECORD") return [text, "Record", "DigitalNZ"].join(" | ");
  else if (type === "STORY") return [text, "Story", "DigitalNZ"].join(" | ");
  else if (type === "SEARCH") {
    let base = ["Records", "DigitalNZ"];

    if (tab) base.unshift(tab);

    if (filters && filters.length > 0) {
      const filterString = filters.map((filter) => {
        return values(filter).join("=");
      });
      base.unshift(filterString.join(" "));
    }

    base.unshift(text);
    return base.join(" | ");
  }
}
