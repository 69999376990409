import { useDispatch, useSelector } from "react-redux";
import Modal from "src/components/Modal";
import ImageDescriberForm from "src/components/StoriesPage/story/adduserimage/ImageDescriberForm";
import {
  closeEditUserImageModal,
  selectEditUserImage,
  setEditingUserImage,
} from "src/features/EditUserImageSlice";
import { updateBlockData } from "src/features/ItemsSlice";
import {
  selectRecordById,
  submitUserImageEdit,
} from "src/features/RecordsSlice";

const EditUserImageModal = () => {
  const dispatch = useDispatch();

  const { isFetchingUserImageRecord, isSubmitting, editUserImageModalOpened } =
    useSelector(selectEditUserImage);

  const { thumbnailImageUrl, userImage } = useSelector((state) => {
    const { userImageBeingEdited } = selectEditUserImage(state);
    const record = selectRecordById(state, userImageBeingEdited);

    return {
      thumbnailImageUrl: record?.thumbnailImageUrl,
      userImage: record?.userImage,
    };
  });

  const handleValidDescriptionSubmit = (attributes) => {
    dispatch(submitUserImageEdit({ ...attributes, id: userImage.id }));
    dispatch(
      updateBlockData({ recordId: userImage.recordId, title: attributes.title })
    );
  };

  return (
    <Modal
      isOpen={editUserImageModalOpened}
      onRequestClose={() => dispatch(closeEditUserImageModal())}
      onAfterClose={() => dispatch(setEditingUserImage(null))}
      className="generic-modal modal--extra-wide"
      title="Edit your image"
    >
      {isFetchingUserImageRecord ? (
        <div className="generic-modal__content">Loading...</div>
      ) : (
        <ImageDescriberForm
          image={thumbnailImageUrl}
          {...userImage}
          onValidDescriptionSubmit={handleValidDescriptionSubmit}
          onBack={() => dispatch(closeEditUserImageModal())}
          backLabel="Cancel"
          isSubmitting={isSubmitting}
          submitLabel="Save"
        />
      )}
    </Modal>
  );
};

export default EditUserImageModal;
