import { map } from "lodash";

import generateIconTitle from "src/utils/generateIconTitle";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";
import convertArray from "src/utils/convertArray.js";

import RecordImage from "./RecordImage.jsx";

const RecordPreview = ({ record }) => {
  return (
    <div className="grid-container record-preview">
      <p className="record-preview__category">
        {convertArray(
          map(record.category, (category) => generateIconTitle([category])),
          " / "
        )}
      </p>
      <h1 className="record-preview__title">
        {unescapeXMLCharacters(record.title)}
      </h1>
      <RecordImage {...record} />
    </div>
  );
};

export default RecordPreview;
