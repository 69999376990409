import { useDispatch, useSelector } from "react-redux";

import { CSSTransition } from "react-transition-group";

import { openMobileFilters, selectSearch } from "src/features/SearchSlice";

import { fromPairs, reduce, sortBy, toPairs } from "lodash";
import MasonryLayoutSwitch from "src/components/MasonryLayoutSwitch";
import FilterList from "./FilterList";
import MobileFiltersIndex from "./MobileFiltersIndex";

const MobileFiltersApp = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);

  const filterButtonText = () => {
    if (search.filters.length > 0 || search.activeTab != "All") {
      return "Change or clear your filters";
    } else {
      return "Filter these results";
    }
  };

  // Convert the format filters so that they are in the same format as the panel facets
  // So that the FilterList accepts normalized data.
  const formatFilters = () => {
    let filters = sortBy(search.primaryCategoryCounts, [
      function (filter) {
        return filter.category;
      },
    ]);

    return reduce(
      filters,
      function (result, value) {
        result[value.category] = value.count;
        return result;
      },
      {}
    );
  };

  const sortedFilters = (filters) => {
    let sortedFilters = sortBy(toPairs(filters), ([k, _v]) => {
      k = k.toUpperCase();
      return k;
    });

    return fromPairs(sortedFilters);
  };

  return (
    <>
      <div className="search-container__small-secondary-actions">
        <button
          className="button expanded search-container__filter-button"
          onClick={() => dispatch(openMobileFilters())}
        >
          {filterButtonText()}
        </button>

        <MasonryLayoutSwitch />
      </div>

      <CSSTransition
        classNames="filters-ltr"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState != "closed"}
      >
        <MobileFiltersIndex />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "format"}
      >
        <FilterList facet="format" name="Format" filters={formatFilters()} />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "content-partners"}
      >
        <FilterList
          multiple="true"
          facet="content_partner"
          name="Content Partners"
          filters={sortedFilters(search.panel.facets.content_partner)}
        />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "collections"}
      >
        <FilterList
          multiple="true"
          facet="primary_collection"
          name="Collections"
          filters={sortedFilters(search.panel.facets.primary_collection)}
        />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "usage"}
      >
        <FilterList
          multiple="true"
          facet="usage"
          name="Usage"
          filters={sortedFilters(search.panel.facets.usage)}
        />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "decades"}
      >
        <FilterList
          multiple="true"
          facet="decade"
          name="Decades"
          filters={search.panel.facets.decade}
        />
      </CSSTransition>

      <CSSTransition
        classNames="filters-rtl"
        timeout={600}
        unmountOnExit
        exit={true}
        in={search.mobileFiltersState == "date_range"}
      >
        <FilterList
          multiple="true"
          facet="date_range"
          name="Date Range"
          filters=""
        />
      </CSSTransition>
    </>
  );
};

export default MobileFiltersApp;
