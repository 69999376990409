import { Component } from "react";
import { connect } from "react-redux";
import { bindAll, findIndex, chunk, map, isEmpty, head } from "lodash";
import AddTextArea from "./AddTextArea";
import AddUserImageModal from "./adduserimage";
import Modal from "src/components/Modal";
import { request } from "src/utils/request";
import { Row } from "./Row";
import $ from "jquery";

import { selectStoryById } from "src/features/StoriesSlice";
import { selectUser } from "src/features/UserSlice";
import { selectEditStory } from "src/features/EditStorySlice";
import {
  blockFakeAdd,
  blockFakeRemove,
  blockAdd,
  repositionApiBlocks,
  selectBlocks,
} from "src/features/ItemsSlice";

import { isInternalLink } from "src/utils/isExternalLink";
import { featureIsDisabled } from "src/features/PlugFeaturesSlice";

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageModalIsOpened: false,
      errorModalIsOpened: false,
      errors: [],
      uploadingImage: false,
    };
    bindAll(
      this,
      "openModal",
      "closeModal",
      "handleImageUploadSubmit",
      "openUploadImageModalButton"
    );
  }

  addExternalLinkStyles() {
    $(".main a").each(function (_idx, link) {
      var href = $(link).attr("href");

      if (isInternalLink(href)) {
        return;
      }

      $(link).addClass("external-link");
    });
  }

  componentDidMount() {
    this.addExternalLinkStyles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blocks != this.props.blocks) {
      this.props.dispatch(
        repositionApiBlocks({
          storyId: this.props.storyId,
          blocks: this.props.blocks,
        })
      );
    }
    if (process.env.NODE_ENV != "test") {
      try {
        Foundation.reInit("equalizer");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    this.addExternalLinkStyles();
  }

  somethingWentWrong() {
    return (
      <>
        We're sorry, but something went wrong. For support, please get in touch
        with us at{" "}
        <a
          target="_blank"
          href="mailto:info@digitalnz.org?subject=User upload failed"
        >
          info@digitalnz.org
        </a>
      </>
    );
  }

  openModal() {
    request({ url: "/api/user_images.json" })
      .then((response) => response.json())
      .then((json) => {
        if (json.numberOfImages >= json.maxImagesPerUser) {
          this.setState({
            errorModalIsOpened: true,
            errors: [
              "You have reached your upload limit of " +
                json.maxImagesPerUser +
                " images.",
            ],
          });
        } else {
          this.setState({ imageModalIsOpened: true });
        }
      })
      .catch((error) => {
        this.setState({
          errorModalIsOpened: true,
          imageModalIsOpened: true,
          errors:
            error && error.errors ? error.errors : [this.somethingWentWrong()],
        });
      });
  }

  closeModal() {
    if (this.state.imageModalIsOpened) {
      this.setState({ imageModalIsOpened: false });
    }
    if (this.state.errorModalIsOpened) {
      this.setState({ errorModalIsOpened: false });
    }
  }

  handleImageUploadSubmit(imageObject) {
    const formData = new FormData();
    const fakeId = "fake-image-card-id";

    this.setState({ uploadingImage: true });

    this.props.dispatch(blockFakeAdd({ title: imageObject.title, id: fakeId }));

    formData.append("user_image[title]", imageObject.title);
    formData.append("user_image[description]", imageObject.description);
    if (imageObject.subject)
      formData.append("user_image[subject]", imageObject.subject);
    if (imageObject.displayDate)
      formData.append("user_image[display_date]", imageObject.displayDate);
    if (imageObject.creator)
      formData.append("user_image[creator]", imageObject.creator);
    formData.append("user_image[license]", imageObject.license);
    formData.append(
      "user_image[image]",
      imageObject.image,
      imageObject.image.path
    );

    request({
      url: "/api/user_images",
      body: formData,
      options: { method: "POST" },
      stringify: false,
    })
      .then((response) => response.json())
      .then(async (record) => {
        this.setState({
          imageModalIsOpened: false,
        });

        await this.props.dispatch(
          blockAdd({
            storyId: this.props.storyId,
            type: "embed",
            subType: "record",
            content: record,
            position: 1,
          })
        );

        this.setState({ uploadingImage: false });
      })
      .catch((error) => {
        this.props.dispatch(blockFakeRemove(fakeId));
        this.setState({
          errorModalIsOpened: true,
          imageModalIsOpened: false,
          uploadingImage: false,
          errors:
            error && error.errors ? error.errors : [this.somethingWentWrong()],
        });
      });
  }

  openUploadImageModalButton() {
    if (!this.props.editable) return;
    if (this.props.imageUploadDisabled) return;

    return (
      <div className="grid-x d-print-none">
        <div className="cell small-offset-1 small-10 medium-offset-3 medium-6">
          <div className="story-blocks__block-container story-blocks__block-container--upload-banner text-center">
            <button
              className="button"
              id="add-images"
              disabled={this.state.uploadingImage ? "disabled" : ""}
              onClick={this.openModal}
            >
              <span>
                <i className="fa fa-cloud-upload start"></i>
                Upload your image
              </span>
            </button>

            <p className="text-center">
              Uploads are limited to a file size of 15 megabytes and jpg, png,
              and gif formats.
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { blocks } = this.props;
    const findBlockIndex = (id) =>
      findIndex(this.props.blocks, (b) => b.id === id);

    const rows = chunk(this.props.blocks, 3);

    return (
      <div className="story-blocks grid-container">
        {this.openUploadImageModalButton()}
        <div className="grid-x">
          {(isEmpty(blocks) || blocks[0].type !== "text") &&
            this.props.editable && (
              <AddTextArea
                index={0}
                disabled={!isEmpty(this.props.activeTextItem)}
              />
            )}
        </div>

        {map(rows, (row, index) => {
          return (
            <Row
              contents={row}
              findBlockIndex={findBlockIndex}
              id={index}
              key={`row-${index}`}
              {...this.props}
            />
          );
        })}

        <AddUserImageModal
          onRequestClose={this.closeModal}
          isOpen={this.state.imageModalIsOpened}
          onImageUploadSubmit={this.handleImageUploadSubmit}
        />
        <Modal
          onRequestClose={this.closeModal}
          isOpen={this.state.errorModalIsOpened}
          className="generic-modal add-image"
          title="Sorry, something went wrong"
        >
          <div className="generic-modal__content">
            <p>{head(this.state.errors)}</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect((state, ownProps) => ({
  imageUploadDisabled: featureIsDisabled(state, "user-image-upload"),
  blocks: selectBlocks(state),
  activeTextItem: selectEditStory(state).activeTextItem,
  activeRecordItem: selectEditStory(state).activeRecordItem,
  activeSlot: selectEditStory(state).activeSlot,
  user: selectUser(state),
  metadata: selectStoryById(state, ownProps.storyId),
}))(Story);
