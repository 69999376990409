import { Provider } from "react-redux";
import AddToStoryModalBehaviour from "src/components/AddToStoryModalBehaviour";
import AddToStoryNotification from "src/components/AddToStoryNotification";
import ErrorBoundary from "src/components/ErrorBoundary";
import MyUploadedItems from "src/components/MyUploadedItems";
import EditUserImageModal from "src/components/RecordCard/EditUserImageModal";

import { recordsReducer } from "src/reducers";
import configureAppStore from "src/store";

const MyUploadedItemsApp = (props) => {
  return (
    <ErrorBoundary>
      <Provider store={configureAppStore(recordsReducer, props)}>
        <MyUploadedItems />
        <EditUserImageModal />
        <AddToStoryModalBehaviour />
        <AddToStoryNotification />
      </Provider>
    </ErrorBoundary>
  );
};

export default MyUploadedItemsApp;
