import isNil from "lodash/isNil";
import merge from "lodash/merge";
import inBrowser from "src/utils/inBrowser";
import { camelCaseToSnake } from "src/utils/keyConverters";

export function csrfParam() {
  if (inBrowser()) {
    return $("meta[name=csrf-param]").attr("content");
  }
}

export function csrfToken() {
  if (inBrowser()) {
    return $("meta[name=csrf-token]").attr("content");
  }
}

export function request({ url, body, options, stringify = true }) {
  let bodyOption = null;
  if (stringify) {
    bodyOption = isNil(body)
      ? {}
      : {
          body: JSON.stringify(camelCaseToSnake(body)),
        };
  } else {
    bodyOption = { body: body };
  }

  let headers = {};
  if (inBrowser()) {
    headers["X-CSRF-Token"] = csrfToken();
  }
  if (!(body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const fetchOptions = merge(
    {},
    {
      credentials: "include",
      headers: headers,
    },
    bodyOption,
    options
  );

  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        let rejectError = null;
        try {
          rejectError = JSON.parse(text);
        } finally {
          rejectError =
            rejectError ||
            `${url}: ${response.status} ${response.statusText}: ${text}`;
        }
        return Promise.reject(rejectError);
      });
    }
    return Promise.resolve(response);
  });
}
