import $ from "jquery";
import { filter, isEmpty, map } from "lodash";
import generateSearchParams from "src/utils/generateSearchParams";

function NoResults({ categories, activeCategory, currentSearch, filters }) {
  let categoriesWithResults = filter(categories, function (category) {
    return category.count !== "0" && category.category !== "More";
  });

  function suggestedResults() {
    let categoryLinks = map(categoriesWithResults, (arrayItem, _index) => {
      let params = generateSearchParams(currentSearch, filters, null);

      if (arrayItem.category !== "All") {
        params = generateSearchParams(
          currentSearch,
          filters,
          arrayItem.category,
          1
        );
      }

      return (
        <li key={arrayItem.category}>
          <a
            title={`Search in the ${arrayItem.category} category`}
            href={`/records?${$.param(params)}`}
          >
            {arrayItem.category} ({arrayItem.count})
          </a>
        </li>
      );
    });

    return <span>{categoryLinks}</span>;
  }

  function message() {
    if (activeCategory !== "All" && !isEmpty(categoriesWithResults)) {
      return (
        <>
          <p>
            <strong>
              Sorry, there are no results for '{currentSearch}' in{" "}
              {activeCategory}. However, there are results for '{currentSearch}'
              in these other content types:
            </strong>
          </p>
          <ul className="no-results__list">{suggestedResults()}</ul>
        </>
      );
    } else if (isEmpty(filters)) {
      return (
        <p>
          <strong>
            Sorry, there are no results for your search. Please try searching
            with a different word or refining your search. For example, you
            could remove a date or a few words to see if this brings back more
            results.
          </strong>
        </p>
      );
    } else {
      return (
        <p>
          <strong>
            Sorry, there are no results for your search. Please try searching{" "}
            <a
              href={
                "/records?" +
                $.param(generateSearchParams(currentSearch, {}, {}))
              }
            >
              without filters applied.
            </a>
          </strong>
        </p>
      );
    }
  }

  return (
    <div className="grid-container">
      <div className="no-results">{message()}</div>
    </div>
  );
}

export default NoResults;
