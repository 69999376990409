import generateIconClass from "src/utils/generateIconClass";
import linkToOriginalRecord from "src/utils/linkToOriginalRecord";
import truncate from "lodash/truncate";
import { includes } from "lodash";
import Analytics from "src/utils/Analytics";

function RecordImage({
  youtubeEmbed,
  thumbnailImageUrl,
  landingUrl,
  title,
  sourceUrl,
  objectUrl,
  category,
  belongsToUser,
}) {
  const badge = (
    <div className="top-triangle-container__label">
      <span className="top-triangle-container__label__content">
        YOUR UPLOAD
      </span>
    </div>
  );

  const iconClass = generateIconClass(category);
  const urlToOriginalRecord = linkToOriginalRecord(
    objectUrl,
    sourceUrl,
    landingUrl
  );

  if (youtubeEmbed) {
    return (
      <div className="grid-x record-preview__container">
        <div className="cell auto"></div>
        <div className="cell large-6 medium-9 small-12">
          <div className="record-preview__responsive-embed">
            <iframe src={youtubeEmbed} frameBorder="0"></iframe>
          </div>
        </div>
        <div className="cell auto"></div>
      </div>
    );
  }
  let linkProps = { href: urlToOriginalRecord };

  if (!includes(landingUrl, "digitalnz.org/records/")) {
    linkProps.title =
      "This link will leave DigitalNZ. Use your browser's Back button to return here.";
    linkProps["ga-category"] = "Click Through";
    linkProps["ga-action"] = "Landing page - Image";
    linkProps["ga-label"] = truncate(sourceUrl, { length: 125 });
    linkProps.onClick = Analytics.onClick;
  }

  return (
    <div className="grid-x record-preview__container">
      {belongsToUser && badge}
      <div className="cell auto"></div>
      <div className="cell shrink record-preview__image-container">
        <a {...linkProps}>
          {thumbnailImageUrl ? (
            <img
              className="record-preview__image"
              src={thumbnailImageUrl}
              alt={title}
            />
          ) : (
            <i
              className={`icon icon--large ${iconClass} record-preview__icon`}
            ></i>
          )}
        </a>
      </div>
      <div className="cell auto"></div>
    </div>
  );
}

export default RecordImage;
