import { map } from "lodash";
import { useSelector } from "react-redux";
import { selectSearch } from "src/features/SearchSlice";
import CategoryTab from "./CategoryTab";
import DropDown from "./DropDown";

const SearchTab = (_props) => {
  const {
    primaryCategoryCounts,
    secondaryCategoryCounts,
    activeTab,
    query,
    filters,
  } = useSelector(selectSearch);

  return (
    <nav
      className="search-tabs show-for-medium grid-container"
      role="navigation"
    >
      <ul className="grid-x" role="menubar">
        {map(primaryCategoryCounts, (tab, index) => {
          return (
            <CategoryTab
              categoryName={tab.category}
              classNames="search-tabs__filter medium-2 cell"
              filters={filters}
              count={tab.count}
              activeCategory={activeTab}
              searchValue={query}
              key={`search-tab-${index}`}
            />
          );
        })}
        <DropDown
          categoryName="More"
          activeCategory={activeTab}
          categoryStats={secondaryCategoryCounts}
          searchValue={query}
          filters={filters}
        />
      </ul>
    </nav>
  );
};

export default SearchTab;
