import { flatten, isEmpty } from "lodash";
import { Fragment } from "react";
import { convertArrayItemsToLinks } from "./RecordDetails";

const ExperimentalBlock = (props) => {
  if (process.env.RAILS_ENV === "production") return null;
  if (isEmpty(flatten(props.entities.map((entity) => entity.tags))))
    return null;

  return (
    <div className="experimental">
      <h4 className="experimental__heading">
        {props.title}
        <span className="experimental__pill">Experimental</span>
      </h4>
      <dl className="grid-x grid-margin-x record-details">
        {props.entities.map((entity) => (
          <Fragment key={entity.title}>
            {!isEmpty(entity.tags) && (
              <>
                <dt className="cell small-12 medium-3 record-details__attr">
                  {entity.title}
                </dt>
                <dd className="cell small-12 medium-9 record-details__value experimental__value">
                  {convertArrayItemsToLinks(
                    entity.tags,
                    `i[${entity.solrAttr}]`
                  )}
                </dd>
              </>
            )}
          </Fragment>
        ))}
      </dl>
      {props.description}
    </div>
  );
};

export default ExperimentalBlock;
