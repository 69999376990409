import { isEmpty, slice, map } from "lodash";

const AddToStoryDescription = ({ preContent, storyNames }) => {
  if (isEmpty(storyNames)) return null;

  let content = (
    <>
      {preContent} <strong>'{storyNames[0]}'</strong>
    </>
  );

  const leftStoryNames = slice(storyNames, 1, storyNames.length);
  if (storyNames.length > 1) {
    content = (
      <>
        {content} +{" "}
        <strong>
          <abbr title={map(leftStoryNames, (name) => `'${name}'`).join(", ")}>
            {storyNames.length - 1} {storyNames.length > 2 ? "others" : "other"}
          </abbr>
        </strong>
      </>
    );
  }

  return content;
};

export default AddToStoryDescription;
