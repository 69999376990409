import moment from "moment";

export default (startDate, endDate) => {
  if (startDate === endDate) return [startDate];

  const dates = [startDate];
  let date = startDate;
  while (date !== endDate) {
    date = moment(date).add(1, "day").format("YYYY-MM-DD");
    dates.push(date);
  }

  return dates;
};
