import { map } from "lodash";
import { useSelector } from "react-redux";
import RecordCard from "src/components/RecordCard/RecordCard";
import withMyUploadedItems from "src/components/RecordCard/hoc/withMyUploadedItems";
import { selectAddToStory } from "src/features/AddToStorySlice";
import { selectAllRecords } from "src/features/RecordsSlice";

const MyUploadedItems = () => {
  const records = useSelector(selectAllRecords);
  const { activeRecord } = useSelector(selectAddToStory);
  const WithMyUploadedItems = withMyUploadedItems(RecordCard);

  return (
    <div className="grid-container">
      <h1>My Uploaded Images</h1>
      <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-4">
        {map(records, (record) => (
          <div className="cell">
            <WithMyUploadedItems
              key={record.id}
              {...record}
              active={activeRecord}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyUploadedItems;
