import {
  camelCase,
  isArray,
  isObject,
  map,
  mapKeys,
  mapValues,
  snakeCase,
} from "lodash";

function convertKeys(conversionFunction, target) {
  const boundWithConversion = convertKeys.bind(null, conversionFunction);

  if (isArray(target)) return map(target, boundWithConversion);
  if (!isObject(target)) return target;

  const targetWithFixedKeys = mapKeys(target, (_value, key) =>
    conversionFunction(key)
  );

  return mapValues(targetWithFixedKeys, (v) => {
    // The order of this is important, if istarget is first arrays are converted to targets
    if (isArray(v)) return map(v, boundWithConversion);
    if (isObject(v)) return boundWithConversion(v);

    return v;
  });
}

export function snakeToCamelCase(target) {
  return convertKeys(camelCase, target);
}

export function camelCaseToSnake(target) {
  return convertKeys(snakeCase, target);
}
