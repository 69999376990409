require("isomorphic-fetch");
require("es6-promise/auto");

import { Component } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server.js";

import Analytics from "src/utils/Analytics";
import inBrowser from "src/utils/inBrowser";

import { selectSearch } from "src/features/SearchSlice";
import { recordsReducer } from "src/reducers";
import configureAppStore from "src/store";

import SearchPage from "src/components/SearchPage/SearchPage";
import SearchPageResultsOnly from "src/components/SearchPage/SearchResultsOnlyApp";
import { ShowStory } from "src/components/StoriesPage/pages";

import AddToStoryNotification from "src/components/AddToStoryNotification";
import ErrorBoundary from "src/components/ErrorBoundary";
import EditUserImageModal from "src/components/RecordCard/EditUserImageModal";
import RecordPage from "src/components/RecordPage";

class SearchPageApp extends Component {
  constructor(props) {
    super();

    this.location = props.location;
    const search = selectSearch(props);

    const activeTab = search.activeTab;
    const combinedStats = search.primaryCategoryCounts.concat(
      search.secondaryCategoryCounts
    );

    const activeCategoryStats = combinedStats.find(
      (categoryStat) => categoryStat.category === activeTab
    );

    if (activeCategoryStats) {
      const totalResults = activeCategoryStats.count;
      if (totalResults === "0") {
        let eventLabel = search.query;

        Analytics.event("Search", "Search No Results", eventLabel);
        Analytics.event(
          "SearchQuery",
          "Search No Results",
          window.location.search
        );
      }

      Analytics.event("Result Count", "Total Results", totalResults);
    }
  }

  render() {
    const Router = inBrowser() ? BrowserRouter : StaticRouter;

    return (
      <ErrorBoundary>
        <Provider store={configureAppStore(recordsReducer, this.props)}>
          <DndProvider backend={HTML5Backend}>
            <Router location={this.location} context={{}}>
              <Routes>
                <Route path="/records" element={<SearchPage />} />
                <Route
                  path="/concepts/:id"
                  element={<SearchPageResultsOnly />}
                />
                <Route path="/records/:id" element={<RecordPage />} />
                <Route path="/records/:id/*" element={<RecordPage />} />
                <Route path="/stories/:id" element={<ShowStory />} />
              </Routes>

              <EditUserImageModal />
              <AddToStoryNotification />
            </Router>
          </DndProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default SearchPageApp;
