import { Component } from "react";
import autosize from "autosize";
import bindAll from "lodash/bindAll";
import unescape from "lodash/unescape";
import classNames from "classnames";

class TextForm extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
    bindAll(this, "onChange", "submitChanges", "finishEditingKeyDown");
  }

  componentDidMount() {
    autosize(this.textArea);
  }

  componentWillUnmount() {
    this.submitChanges();
  }

  onChange(e) {
    this.setState({ value: e.target.value });
    autosize(e.target);
  }

  submitChanges() {
    const { updateCb } = this.props;
    const { value } = this.state;

    // don't hammer the api
    if (this.props.value !== value) updateCb(value);
  }

  finishEditingKeyDown(e) {
    if (e.key === "Enter") this.submitChanges();
  }

  render() {
    const { value } = this.state;
    const { placeholder, id, maxLength } = this.props;
    const rows = 1;
    return (
      <textarea
        aria-label={id.replace("-", " ")}
        rows={rows}
        className={classNames("story-name", this.props.className)}
        id={id}
        type="text"
        maxLength={maxLength}
        placeholder={placeholder}
        ref={(input) => {
          this.textArea = input;
        }}
        onChange={this.onChange}
        onKeyDown={this.finishEditingKeyDown}
        onBlur={this.submitChanges}
        value={unescape(value)}
      />
    );
  }
}

export default TextForm;
