import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSearch, toggleFacet } from "src/features/SearchSlice";
import { performSearch } from "src/utils/search";

const IsolatedFilter = ({ filter }) => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);

  useEffect(() => {
    if (search.refreshWithNewFilters) {
      performSearch(dispatch, search);
    }
  }, [search, dispatch]);

  const removeFilter = () => {
    dispatch(toggleFacet({ refreshWithNewFilters: true, filter: filter }));
  };

  function displayTitleFor(filter) {
    return filter.replace("_", "-");
  }

  return (
    <button
      className="search-panel__header__item search-panel__header__item--with-filters"
      onClick={() => removeFilter()}
    >
      {displayTitleFor(filter.type || filter.facet)}:{" "}
      {filter.customLabel || filter.value}
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  );
};

export default IsolatedFilter;
