import { createSlice } from "@reduxjs/toolkit";
import { remove } from "lodash";

const addToStorySlice = createSlice({
  name: "addToStory",
  initialState: {},
  reducers: {
    /////////////////////////////
    // MODAL
    /////////////////////////////
    openModal(state) {
      state.modalIsOpened = true;
    },
    closeModal(state) {
      // better to have a modalIsOpened state for the animation
      // if you set activeRecord to null and rely on this to display the modal
      // or not. The modal will get blank and then close which is ugly
      state.modalIsOpened = false;
    },
    updateActiveRecord(state, action) {
      state.modalIsOpened = action.payload !== null;
      state.activeRecord = action.payload;
    },
    setModalIsProcessing(state) {
      state.modalIsProcessing = true;
    },
    unsetModalIsProcessing(state) {
      state.modalIsProcessing = false;
    },
    /////////////////////////////
    // NOTIFICATION
    /////////////////////////////
    openNotification(state) {
      // if the notification is already opened, we need a transition
      // to tell the user that it's a new notification
      // the number helps the AddToStoryNotification to create an animation
      if (state.notificationOpened) {
        state.notificationNumber = (state.notificationNumber + 1) % 2;
      }
      state.notificationOpened = true;
    },
    closeNotification(state) {
      state.notificationNumber = null;
      state.notificationOpened = false;
    },
    addProcessingRecord(state, action) {
      state.processingRecordIds.push(action.payload);
    },
    removeProcessingRecord(state, action) {
      remove(state.processingRecordIds, (id) => id == action.payload);
    },
    setStoryIdsToAlwaysAdd(state, action) {
      state.storyIdsToAlwaysAdd = action.payload;
    },
    toggleAlwaysAdd(state) {
      state.alwaysAddToStory = !state.alwaysAddToStory;
    },
  },
});

export const selectAddToStory = (state) => state.ui.addToStory;

const { actions, reducer } = addToStorySlice;
export const {
  openNotification,
  closeNotification,
  addProcessingRecord,
  removeProcessingRecord,
  updateActiveRecord,
  toggleAlwaysAdd,
  setStoryIdsToAlwaysAdd,
  setModalIsProcessing,
  unsetModalIsProcessing,
  closeModal,
  openModal,
} = actions;

export default reducer;
