import { Component } from "react";
import ImageCopyrightItem from "./ImageCopyrightItem";

import CopyrightImage from "images/copyright.png";
import CreativeCommonsImage from "images/creative-commons.png";
import ThumbsUpImage from "images/thumbs-up.png";

class ImageCopyrightForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.license,
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(copyright) {
    this.setState({
      selected: copyright,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.selected === null) {
      this.setState({
        errors: ["Please select a licence field"],
      });
    } else {
      this.props.onValidCopyrightSubmit(this.state.selected);
    }
  }

  render() {
    const selected = this.state.selected;
    return (
      <form onSubmit={this.handleSubmit} className="copyright">
        <div className="grid-x grid-margin-x generic-modal__content">
          <div className="cell medium-6 image-being-uploaded-box">
            <img src={this.props.image} className="image-being-uploaded" />
          </div>
          <div className="cell medium-6">
            <h2>What images can I upload to DigitalNZ?</h2>
            <p>
              If you created this image, you are the copyright holder and are
              free to upload it to DigitalNZ. If you're uploading an image
              created by someone else, you need to have their permission to
              upload it, or know that it is free of copyright.
            </p>

            <h2>How do I find the copyright status of this image?</h2>
            <p>
              Use our{" "}
              <a
                href="/make-it-digital/enabling-use-re-use/copyright-status-flowchart"
                target="_blank"
              >
                copyright status flowchart
              </a>{" "}
              or this{" "}
              <a
                href="https://www.facebook.com/copyrightchatbot/"
                target="_blank"
              >
                Facebook messenger chatbot.
              </a>
            </p>

            <div role="radiogroup" aria-labelledby="copyright-radios">
              <h2 id="copyright-radios">
                How can other people use this image?
              </h2>
              <p>Please select one of the options below:</p>

              {this.state.errors.length > 0 &&
                this.state.errors.map((error, i) => (
                  <p className="error" key={i}>
                    <span className="error-icon"></span>
                    {error}
                  </p>
                ))}

              <ImageCopyrightItem
                selected={selected == "cc4"}
                onClick={() => this.handleSelectChange("cc4")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32) this.handleSelectChange("cc4");
                }}
                imgSrc={CreativeCommonsImage}
                title={
                  <a
                    href="https://creativecommons.org/licenses/by/4.0/"
                    target="_blank"
                  >
                    Creative Commons 4.0 Attribution licence.
                  </a>
                }
                description="The image can be used by anyone if they credit the copyright holder."
              />

              <ImageCopyrightItem
                selected={selected == "no-known-copyrights"}
                onClick={() => this.handleSelectChange("no-known-copyrights")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32)
                    this.handleSelectChange("no-known-copyrights");
                }}
                imgSrc={ThumbsUpImage}
                title="No known copyright."
                description="The image can be shared, modified, and used commercially."
              />

              <ImageCopyrightItem
                selected={selected == "all-rights-reserved"}
                onClick={() => this.handleSelectChange("all-rights-reserved")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32)
                    this.handleSelectChange("all-rights-reserved");
                }}
                imgSrc={CopyrightImage}
                title="All rights reserved."
                description="The image cannot be used without the permission of the copyright holder."
              />
            </div>
          </div>
        </div>

        <hr />
        <div className="generic-modal__actions">
          <button
            className="button clear"
            onClick={this.props.onBack}
            type="button"
          >
            Back
          </button>
          <button className="button" disabled={selected == null} type="submit">
            Next
          </button>
        </div>
      </form>
    );
  }
}

export default ImageCopyrightForm;
