import { chain, includes, keys, map, size } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Analytics from "src/utils/Analytics";
import { performSearch } from "src/utils/search";

import DateRangeFilter from "./DateRangeFilter";
import FacetFilter from "./FacetFilter";
import FacetFilterActionBar from "./FacetFilterActionBar";
import FacetFilterTab from "./FacetFilterTab";
import IsolatedFilter from "./IsolatedFilter";

import MasonryLayoutSwitch from "src/components/MasonryLayoutSwitch";
import {
  clearAllFacets,
  selectSearch,
  toggleFacet,
  toggleFacetTab,
} from "src/features/SearchSlice";

const DesktopFilter = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const activeFacetTab = search.panel.activeFacetTab;
  const activeFilters = search.filters;
  const facetFiltersWithCount = search.panel.facets;
  const hasDifferentFilters = search.hasDifferentFilters;

  useEffect(() => {
    if (search.refreshWithNewFilters) {
      performSearch(dispatch, search);
    }
  }, [search, dispatch]);

  const facetFilterTabs = {
    content_partner: { order: "asc" },
    primary_collection: { order: "asc" },
    usage: { order: "asc" },
    decade: { order: "desc" },
    date_range: { order: "asc" },
  };

  const alwaysDisplayedFilters = [
    "decade",
    "content_partner",
    "primary_collection",
    "usage",
    "date_range",
  ];

  const getOrderedFacetFiltersByFacetAsPair = (facetName) => {
    return (
      chain(facetFiltersWithCount[facetName])
        // I cannnot convert it back to a hash with `fromPairs()`
        // as it will not retain the order when the key is a number
        // so I'm returning a pair [filterName, filterCount]
        .toPairs()
        .orderBy(0, facetFilterTabs[facetName].order)
        .value()
    );
  };

  const getActiveFiltersForFacet = (facetName) =>
    chain(activeFilters)
      .filter((filter) => filter.facet === facetName)
      .map((filter) => filter.value)
      .value();

  const getNumberOfActiveFacets = chain(activeFilters)
    .map((filter) => filter.facet)
    .uniq()
    .size();

  const clearAllFacetsAndSearch = async () => {
    Analytics.event("click", "SearchFilterSelect", "Clear All Facets");
    await dispatch(clearAllFacets({ refreshWithNewFilters: true }));
  };

  const closeFacetFiltersPanel = () => {
    dispatch(toggleFacetTab({ refreshWithNewFilters: hasDifferentFilters }));
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") closeFacetFiltersPanel();
  };

  return (
    <div className="search-panel-app show-for-medium grid-container">
      <div className="search-panel" onKeyUp={handleKeyUp}>
        <div className="search-panel__header" role="menubar">
          <div className="search-panel__filters">
            {chain(facetFiltersWithCount)
              .pick(keys(facetFilterTabs))
              .keys()
              .map((facetName) => (
                <FacetFilterTab key={facetName} facetName={facetName} />
              ))
              .value()}

            {chain(activeFilters)
              .filter(
                (activeFilter) =>
                  !includes(alwaysDisplayedFilters, activeFilter.facet)
              )
              .map((activeFilter) => (
                <IsolatedFilter
                  key={activeFilter.facet}
                  filter={activeFilter}
                />
              ))
              .value()}

            {getNumberOfActiveFacets > 1 && (
              <button
                className="button clear small"
                onClick={clearAllFacetsAndSearch}
              >
                Clear all
                <i className="fa fa-close end" aria-hidden="true"></i>
              </button>
            )}
          </div>
          <MasonryLayoutSwitch />
        </div>

        {activeFacetTab && (
          <>
            <div
              className="search-panel__overlay"
              onClick={() => closeFacetFiltersPanel()}
            />

            <div className="search-panel__content">
              <FacetFilterActionBar
                hasActiveFilters={
                  size(getActiveFiltersForFacet(activeFacetTab)) > 0
                }
                facetName={activeFacetTab}
              />

              <div className="search-panel__content__filters">
                <div className="search-panel__content__filters__items grid-container">
                  <div className="grid-x grid-margin-x grid-margin-y">
                    {activeFacetTab == "date_range" && (
                      <DateRangeFilter enterCallbackType="function" />
                    )}

                    {activeFacetTab != "date_range" &&
                      map(
                        getOrderedFacetFiltersByFacetAsPair(activeFacetTab),
                        ([facetFilterName, facetFilterCount]) => (
                          <FacetFilter
                            key={facetFilterName}
                            toggleFacetFilter={() =>
                              dispatch(
                                toggleFacet({
                                  filter: {
                                    facet: activeFacetTab,
                                    value: facetFilterName,
                                  },
                                })
                              )
                            }
                            isActive={includes(
                              getActiveFiltersForFacet(activeFacetTab),
                              facetFilterName
                            )}
                            facetFilterName={
                              activeFacetTab === "decade"
                                ? `${facetFilterName}s`
                                : facetFilterName
                            }
                            facetFilterCount={facetFilterCount}
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DesktopFilter;
