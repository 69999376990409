import { useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { uniq, flatten } from "lodash";
import { UilCloudUpload } from "@iconscout/react-unicons";

const ImageDropzone = ({ onValidImageSubmit }) => {
  const [errors, setErrors] = useState([]);

  const onDropAccepted = (files) => {
    onValidImageSubmit(files[0]);
  };

  const onDropRejected = (files) => {
    let errors = files.map((file) => file.errors.map((error) => error.code));
    errors = uniq(flatten(errors));

    const error_code_to_sentence = {
      "too-many-files": "Only one image allowed.",
      "file-too-large": "The maximum file size is 15MB",
      "file-invalid-type": "The only files allowed are: JPG, PNG or GIF",
    };

    setErrors(errors.map((error) => error_code_to_sentence[error]));
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 15 * 1024 * 1024, // 15 megabytes
    accept: {"image/*": [".png", ".jpg", ".jpeg", ".gif"]},
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const dropzoneClassNames = (isDragActive) => {
    return classNames({
      cell: true,
      dropzone: true,
      "text-center": true,
      "dropzone--active": isDragActive,
    });
  };

  return (
    <div {...getRootProps({ className: dropzoneClassNames(isDragActive) })}>
      <UilCloudUpload size="120" /> {/* 120 makes the svg 80px high, or 5em */}
      <h4>Drag and drop your image here</h4>
      <h5>or</h5>
      <button className="button">Upload image from your device</button>
      <input {...getInputProps()} />
      {errors.length > 0 && (
        <div className="field_with_errors">
          {errors.map((error, i) => (
            <p className="error" key={i}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageDropzone;
