export default function totalPages(search) {
  const activeTab = search.activeTab;
  const combinedStats = search.primaryCategoryCounts.concat(
    search.secondaryCategoryCounts
  );

  const activeCategoryStats = combinedStats.find(
    (categoryStat) => categoryStat.category === activeTab
  );

  const totalRecords = parseInt(activeCategoryStats.count.replace(/,/g, ""));
  const total = Math.ceil(totalRecords / search.per_page);

  if (total > 100) {
    return 100;
  } else {
    return total;
  }
}
