import { Component } from "react";

import activeRadio from "images/active-radio.svg";
import inactiveRadio from "images/inactive-radio.svg";

class ImageCopyrightItem extends Component {
  render() {
    const { selected, onClick, onKeyDown, imgSrc, title, description } =
      this.props;

    const radioSource = selected ? activeRadio : inactiveRadio;

    return (
      <div className="grid-x align-middle" role="radio" aria-checked={selected}>
        <div className="cell shrink">
          <img
            src={radioSource}
            alt=""
            onClick={onClick}
            onKeyDown={onKeyDown}
            className="radio"
            tabIndex="0"
          />
        </div>
        <div className="cell auto" role="label">
          <div
            className={`grid-x grid-margin-x copyright-selector ${
              selected ? "copyright-selector--selected" : ""
            } align-middle`}
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex="0"
          >
            <div className="cell small-3">
              <img src={imgSrc} alt="" className="copyright-selector__image" />
            </div>
            <div className="cell small-9">
              <h3>{title}</h3>
              <p className="show-for-medium">{description}</p>
            </div>
            <div className="show-for-small-only mobile-description">
              {description}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCopyrightItem;
