import { useSelector } from "react-redux";
import { selectEmptySearchFacetById } from "src/features/EmptySearchFacetsSlice";
import Autocomplete from "./Autocomplete";

const AutocompleteField = ({ value, field, onChange }) => {
  const facets = useSelector((state) =>
    selectEmptySearchFacetById(state, field.field)
  );
  const options = Object.keys(facets).map((key) => ({
    value: key,
    label: key,
  }));

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      suggestions={options}
      placeholder="Start typing to select an option..."
    />
  );
};

export default AutocompleteField;
