import classNames from "classnames";
import AutocompleteField from "./AutocompleteField";

const FIELD_OPTIONS = [
  {
    name: "Any field",
    field: "",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Title",
    field: "title",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Description",
    field: "description",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Creator",
    field: "creator",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Subject",
    field: "subject",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Placename",
    field: "placename",
    autocomplete: false,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Format",
    field: "category",
    autocomplete: true,
    excludeEnabled: false,
    roughlyEnabled: false,
  },
  {
    name: "Content partner",
    field: "content_partner",
    autocomplete: true,
    excludeEnabled: false,
    roughlyEnabled: false,
  },
  {
    name: "Collection",
    field: "primary_collection",
    autocomplete: true,
    excludeEnabled: false,
    roughlyEnabled: true,
  },
  {
    name: "Usage",
    field: "usage",
    autocomplete: true,
    excludeEnabled: false,
    roughlyEnabled: false,
  },
];

const SearchRow = ({
  index,
  deletable,
  handleChange,
  handleDeleteRow,
  row,
}) => {
  const currentField = FIELD_OPTIONS.find((field) => field.field == row.field);

  if (!currentField.excludeEnabled && !row.include) {
    handleChange(index, { ...row, include: true });
  }

  if (!currentField.roughlyEnabled && !row.exactMatch) {
    handleChange(index, { ...row, exactMatch: true });
  }

  const handleInclusionExclusionChange = (e) => {
    handleChange(index, {
      ...row,
      include: e.target.value === "true" ? true : false,
    });
  };

  const handleExactRoughChange = (e) => {
    handleChange(index, {
      ...row,
      exactMatch: e.target.value === "true" ? true : false,
    });
  };

  const handleTextChange = (e) => {
    handleChange(index, {
      ...row,
      terms: e.target.value,
    });
  };

  const handleFieldChange = (e) => {
    handleChange(index, {
      ...row,
      field: e.target.value,
    });
  };

  return (
    <div className="grid-x align-middle">
      <div className="cell auto">
        <div className="grid-x align-middle search-row padding-1">
          <div className="cell shrink">
            <select
              id="include"
              className="search-row__field"
              value={row.include}
              disabled={!currentField.excludeEnabled}
              onChange={handleInclusionExclusionChange}
            >
              <option value="true">Include</option>
              <option value="false">Exclude</option>
            </select>
          </div>

          {!currentField.autocomplete && (
            <div className="cell shrink">
              <select
                id="exactMatch"
                className="search-row__field"
                disabled={!currentField.roughlyEnabled}
                value={row.exactMatch}
                onChange={handleExactRoughChange}
              >
                <option value="true">Exactly the phrase</option>
                <option value="false">Roughly the phrase</option>
              </select>
            </div>
          )}

          <div className="cell auto">
            {(currentField.autocomplete && (
              <AutocompleteField
                value={row.terms}
                field={currentField}
                onChange={handleTextChange}
              />
            )) || (
              <input
                className="search-row__field"
                type="text"
                placeholder="Search..."
                value={row.terms}
                onChange={handleTextChange}
              />
            )}
          </div>

          <div className="cell shrink">in</div>

          <div className="cell shrink">
            <select
              id="field"
              className="search-row__field"
              value={row.field}
              onChange={handleFieldChange}
            >
              {FIELD_OPTIONS.map((option) => (
                <option value={option.field} key={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div
        className={classNames("cell shrink padding-horizontal-1", {
          hide: !deletable,
        })}
      >
        <button
          className="button clear"
          onClick={() => handleDeleteRow(index)}
          type="button"
        >
          <span
            className="fa fa-trash search-row__trash"
            aria-label="Delete"
          ></span>
        </button>
      </div>
    </div>
  );
};

export default SearchRow;
