import { map } from "lodash";
import Masonry from "react-masonry-component";
import { Provider } from "react-redux";
import RecordCard from "src/components/RecordCard/RecordCard";
import { selectAllStories } from "src/features/StoriesSlice";
import { recordsReducer } from "src/reducers";
import configureAppStore from "src/store";

const StoriesHomePageApp = (props) => {
  const cards = map(selectAllStories(props), (record) => (
    <div className="cell masonry-card-layout__item" key={record.id}>
      <RecordCard
        id={record.id}
        imageUrl={record.coverThumbnail}
        title={record.name}
        source={record.creator}
        category={[record.category]}
        externalLink={true}
        link={`/stories/${record.id}`}
      />
    </div>
  ));

  return (
    <Provider store={configureAppStore(recordsReducer, props)}>
      <div className="grid-container section__stories__card">
        <Masonry
          className="masonry-card-layout grid-x grid-padding-x small-up-1 medium-up-2 large-up-4"
          options={{ gutter: 0, transitionDuration: 0 }}
        >
          {cards}
        </Masonry>
      </div>
    </Provider>
  );
};

export default StoriesHomePageApp;
