import MediaInRecordPage from "src/components/MediaInRecordPage";
import MoreInformation from "./MoreInformation";
import ThumbsUpImage from "images/thumbs-up.png";

const OtherRights = (props) => {
  let title = props.usage.join(", ");

  return (
    <>
      <MediaInRecordPage
        img_src={ThumbsUpImage}
        img_alt="Thumbs up"
        title={title}
      >
        <p>See below for specifics about how you may use this item.</p>
      </MediaInRecordPage>

      <MoreInformation {...props} />
    </>
  );
};

export default OtherRights;
