import { Component } from "react";
import Modal from "src/components/Modal";
import ImageSelector from "./ImageSelector";
import ImageDescriberForm from "./ImageDescriberForm";
import ImageCopyrightForm from "./ImageCopyrightForm";
import ImageTermsOfUseForm from "./ImageTermsOfUseForm";
import { bindAll } from "lodash";

class AddUserImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();

    bindAll(
      this,
      "handleValidImageSubmit",
      "handleValidDescriptionSubmit",
      "handleValidCopyrightSubmit",
      "handleValidTermsOfUseSubmit",
      "handleRequestClose",
      "handleBackOnImageSelection",
      "handleBackOnDescription",
      "handleBackOnCopyright"
    );
  }

  initialState() {
    return {
      title: "",
      description: "",
      subject: "",
      displayDate: "",
      creator: "",
      license: null,
      image: null,
      imageURL: null,
      stage: "image",
    };
  }

  handleValidImageSubmit(image) {
    this.setState({
      image: image,
      imageURL: URL.createObjectURL(image),
      stage: "description",
    });
  }

  handleValidDescriptionSubmit({
    title,
    description,
    displayDate,
    subject,
    creator,
  }) {
    this.setState({
      title: title,
      description: description,
      displayDate: displayDate,
      subject: subject,
      creator: creator,
      stage: "license",
    });
  }

  handleBackOnImageSelection() {
    this.setState({
      image: null,
      stage: "image",
    });
  }

  handleValidCopyrightSubmit(license) {
    this.setState({
      license: license,
      stage: "terms-of-use",
    });
  }

  handleBackOnDescription() {
    this.setState({
      stage: "description",
    });
  }

  handleValidTermsOfUseSubmit(e) {
    e.preventDefault();

    this.props.onImageUploadSubmit({
      image: this.state.image,
      title: this.state.title,
      description: this.state.description,
      subject: this.state.subject,
      displayDate: this.state.displayDate,
      creator: this.state.creator,
      license: this.state.license,
    });

    this.handleRequestClose();
  }

  handleBackOnCopyright() {
    this.setState({ stage: "license" });
  }

  handleRequestClose() {
    this.setState(this.initialState);
    this.props.onRequestClose();
  }

  render() {
    let content = null;
    let title = "";
    switch (this.state.stage) {
      case "image":
        title = "Add your own images";
        content = (
          <ImageSelector
            onValidImageSubmit={this.handleValidImageSubmit}
            onCancel={this.handleRequestClose}
          />
        );
        break;
      case "description":
        title = "Describe this image";
        content = (
          <ImageDescriberForm
            image={this.state.imageURL}
            title={this.state.title}
            description={this.state.description}
            onValidDescriptionSubmit={this.handleValidDescriptionSubmit}
            onBack={this.handleBackOnImageSelection}
            backLabel="Back"
            submitLabel="Next"
          />
        );
        break;
      case "license":
        title = "Copyright & use of the image";
        content = (
          <ImageCopyrightForm
            image={this.state.imageURL}
            license={this.state.license}
            onValidCopyrightSubmit={this.handleValidCopyrightSubmit}
            onBack={this.handleBackOnDescription}
          />
        );
        break;
      case "terms-of-use":
        title = "Terms of use";
        content = (
          <ImageTermsOfUseForm
            image={this.state.imageURL}
            onBack={this.handleBackOnCopyright}
            onValidTermsOfUseSubmit={this.handleValidTermsOfUseSubmit}
          />
        );
        break;
    }

    return (
      <Modal
        onRequestClose={this.handleRequestClose}
        isOpen={this.props.isOpen}
        className="modal-content generic-modal modal--extra-wide add-image"
        title={title}
      >
        <div className="add-image-content">{content}</div>
      </Modal>
    );
  }
}

export default AddUserImageModal;
