export default {
  OPEN: {
    shareStatus: "Share it",
    shareMessage:
      "This item is suitable for copying and sharing with others, without further permission.",
    modifyStatus: "Modify it",
    modifyMessage:
      "This item is suitable for modifying, remixing and building upon, without further permission.",
    commercialStatus: "Use it commercially",
    commercialMessage:
      "This item is suitable for commercial use, without further permission.",
  },
  UNKNOWN: {
    shareStatus: "Check before sharing",
    shareMessage:
      "You'll need to confirm with the copyright holder before copying and/or sharing this item with others. This includes posting it on your blog, using it in a presentation, or any other public use.",
    modifyStatus: "Check before modifying",
    modifyMessage:
      "You'll need to confirm with the copyright holder before modifying, remixing, or building upon this item.",
    commercialStatus: "Check about commercial use",
    commercialMessage:
      "You'll need to confirm with the copyright holder before using this item for commercial purposes.",
  },
  RESTRICTED: {
    shareStatus: "No sharing",
    shareMessage:
      "You may not copy and/or share this item with others without further permission. This includes posting it on your blog, using it in a presentation, or any other public use.",
    modifyStatus: "No modifying",
    modifyMessage:
      "You are not allowed to adapt or remix this item into any other works.",
    commercialStatus: "No commercial use",
    commercialMessage: "You may not use this item commercially.",
  },
};
