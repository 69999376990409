import { Component } from "react";
import ImageDropzone from "./ImageDropzone";

class ImageSelector extends Component {
  constructor(props) {
    super(props);
    // TODO
    // this.handleImageClick = this.handleImageClick.bind(this);
  }

  // TODO in another story
  // handleImageClick() {

  // }

  render() {
    // TODO in another story
    /*<div className="cell">
      <div className="grid-x grid-margin-x">
        <div className="cell">
          <h5>OR CHOOSE A PREVIOUSLY UPLOADED IMAGE</h5>
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
        <div className="cell small-4 text-center">
          <img onClick={this.handleImageClick} className="thumbnail" src="https://via.placeholder.com/150" />
        </div>
      </div>
    </div>*/
    return (
      <>
        <div className="add-your-image generic-modal__content">
          <p>
            Uploads are limited to a file size of 15 megabytes and jpg, png, and
            gif formats.
          </p>
          <div className="grid-x grid-margin-x">
            <ImageDropzone onValidImageSubmit={this.props.onValidImageSubmit} />
          </div>
          <div className="grid-x add-your-image__help-text">
            <i
              className="fa fa-question-circle-o fa-5x cell show-for-medium medium-2 align-self-middle add-your-image__help-text__icon"
              aria-hidden="true"
            ></i>
            <div className="cell medium-10">
              <h5>Your image</h5>
              <p>
                Upload any image that helps tell your story. Your image may
                contribute to your family history, the story of your local area,
                or simply give more context to your research.
              </p>
              <h5>Privacy of your image</h5>
              <p>
                Your story can be public, private, or hidden.{" "}
                <a href="/help/how-to-make-a-story" target="_blank">
                  Here’s how to change the privacy of your story.
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="generic-modal__actions">
          <button className="button clear" onClick={this.props.onCancel}>
            Cancel
          </button>
        </div>
      </>
    );
  }
}

export default ImageSelector;
