import $ from "jquery";

const InputCounter = {
  init: () => {
    $("label .input-counter").each(function () {
      const $counter = $(this);
      const $input = InputCounter.findRelatedInput($counter);

      $input.is(":focus")
        ? $counter.removeClass("input-counter--hidden")
        : $counter.addClass("input-counter--hidden");

      InputCounter.updateCounter($counter, $input);
      $input.keyup(() => {
        InputCounter.updateCounter($counter, $input);
      });

      $input.focusin(() => {
        $counter.removeClass("input-counter--hidden");
      });
      $input.focusout(() => {
        $counter.addClass("input-counter--hidden");
      });
    });
  },

  updateCounter: ($counter, $input) => {
    const maxLength = parseInt($input.attr("maxlength"));
    const newLength = maxLength - $input.val().length;
    $counter.html(newLength.toString());
  },

  findRelatedInput: ($counter) => {
    const $label = $counter.parents("label");
    const input_name = $label.attr("for");
    if (input_name === undefined) {
      return $label.find("input, textarea");
    } else {
      return $(`#${input_name}`);
    }
  },
};

export default InputCounter;
