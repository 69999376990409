import { useDispatch } from "react-redux";
import { addTextBlock } from "src/features/ItemsSlice";

const AddTextArea = ({ index, disabled }) => {
  let dispatch = useDispatch();

  return (
    <div className="cell">
      <div className="grid-x align-middle story-blocks__add-text">
        <div
          role="presentation"
          className="cell auto story-blocks__add-text__dash"
        ></div>
        <button
          className="button cell hollow shrink story-blocks__add-text__label"
          disabled={disabled}
          aria-label="Click to add text to your story"
          aria-haspopup="true"
          onClick={() => dispatch(addTextBlock({ position: index + 1 }))}
          tabIndex="0"
        >
          <i className="fa fa-plus start"></i>
          Add text
        </button>
        <div
          role="presentation"
          className="cell auto story-blocks__add-text__dash"
        ></div>
      </div>
    </div>
  );
};

export default AddTextArea;
