import { createSlice } from "@reduxjs/toolkit";

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: {
    dropdownID: null,
  },
  reducers: {
    openDropdown(state, action) {
      state.dropdownID = action.payload;
    },
    closeDropdown(state) {
      state.dropdownID = null;
    },
  },
});

export const selectDropdown = (state) => state.ui.dropdown;

const { actions, reducer } = dropdownSlice;

export const { openDropdown, closeDropdown } = actions;

export default reducer;
