import MediaInRecordPage from "src/components/MediaInRecordPage";
import MoreInformation from "./MoreInformation";

import CopyrightImage from "images/copyright.png";

const AllRightsReserved = (props) => {
  const { displayContentPartner, sourceUrl } = props;

  return (
    <>
      <MediaInRecordPage
        img_src={CopyrightImage}
        img_alt="Copyright"
        title="All Rights Reserved"
      >
        <p>
          This item is all rights reserved, which means you'll have to{" "}
          <a href={sourceUrl} target="_blank" className="external-link">
            get permission from {displayContentPartner}
          </a>{" "}
          before using it.
        </p>
      </MediaInRecordPage>

      <MoreInformation {...props} />
    </>
  );
};

export default AllRightsReserved;
