import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import inBrowser from "src/utils/inBrowser";
import generateTitleText from "src/utils/generateTitleText";
import { camelCaseToSnake } from "src/utils/keyConverters";
import { Helmet } from "react-helmet";
import { selectRecordById } from "src/features/RecordsSlice.js";
import { selectSearch } from "src/features/SearchSlice.js";
import { selectAppDetails } from "src/features/AppDetailsSlice.js";

import RecordPageNav from "src/components/RecordPage/RecordPageNav.jsx";
import RecordPreview from "src/components/RecordPage/RecordPreview.jsx";
import RecordDetails from "src/components/RecordPage/RecordDetails.jsx";
import RecordSideInformation from "src/components/RecordPage/RecordSideInformation.jsx";
import RecordFacebookComments from "src/components/RecordPage/RecordFacebookComments.jsx";
import Rights from "src/components/RecordPage/Rights.jsx";
import Usage from "src/components/RecordPage/usage/Usage.jsx";
import AddToStoryModalBehaviour from "src/components/AddToStoryModalBehaviour";
import MoreLikeThisItems from "src/components/RecordPage/MoreLikeThisItems.jsx";
import RecordActions from "src/components/RecordPage/RecordActions.jsx";
import Entities from "src/components/RecordPage/Entities.jsx";
import AutoTags from "src/components/RecordPage/AutoTags.jsx";
import Analytics from "src/utils/Analytics";
import { has } from "lodash";

function mapStateToProps(state, id) {
  id = Number(id);
  return {
    fromSearchResults: has(selectSearch(state), "resultsIds"),
    searchRecords: selectSearch(state).resultsIds?.map((id) =>
      selectRecordById(state, id)
    ),
    searchLoading: selectSearch(state).loading,
    searchValue: selectSearch(state).query,
    filters: selectSearch(state).filters,
    activeTab: selectSearch(state).activeTab,
    page: selectSearch(state).page,
    per_page: selectSearch(state).per_page,
    total_pages: selectSearch(state).total,
    record: selectRecordById(state, id),
    facebookAppId: selectAppDetails(state).facebookAppId,
    recaptchaSiteKey: selectAppDetails(state).recaptchaSiteKey,
  };
}

const RecordPage = () => {
  const { id } = useParams();
  const props = useSelector((state) => mapStateToProps(state, id));

  useEffect(() => {
    if (inBrowser()) window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Analytics.pageView();
    sendDisplayEvent(props.record, "displayContentPartner");
    sendDisplayEvent(props.record, "displayCollection");
  }, [props.record]);

  const sendDisplayEvent = (record, attr) => {
    Analytics.event(
      "Landing Page",
      "Page View",
      `${camelCaseToSnake(attr)}: ${record[attr]}`
    );
  };

  return (
    <>
      <Helmet>
        <title>{generateTitleText(props.record.title, "RECORD")}</title>
      </Helmet>
      {props.fromSearchResults && <RecordPageNav {...props} />}

      <RecordPreview record={props.record} />

      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-8 large-9 md-padding-right-2">
            <div className="show-for-small-only">
              <RecordActions {...props} />
            </div>
            <RecordDetails record={props.record} />
            <Entities record={props.record} />
            <AutoTags record={props.record} />
            <div className="show-for-small-only">
              <hr className="record-section__separator" />
              <RecordSideInformation {...props} />
            </div>
            <Rights {...props.record} />
            <Usage {...props.record} />
          </div>
          <div className="cell show-for-medium medium-4 large-3">
            <RecordActions {...props} />
            <RecordSideInformation
              {...props}
              modifier="record-side-info--small"
            />
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="grid-x">
          <MoreLikeThisItems recordId={props.record.id} />
        </div>
      </div>

      <div className="grid-container record-section">
        <RecordFacebookComments
          {...props.record}
          facebookAppId={props.facebookAppId}
        />
      </div>

      <AddToStoryModalBehaviour />
    </>
  );
};

export default RecordPage;
