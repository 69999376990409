import classNames from "classnames";
import {
  bindAll,
  filter,
  includes,
  intersection,
  isEmpty,
  isEqual,
  map,
  some,
} from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import AddToStoryDescription from "src/components/AddToStoryDescription";
import {
  closeNotification,
  openNotification,
  selectAddToStory,
  updateActiveRecord,
} from "src/features/AddToStorySlice";
import { selectAllStories } from "src/features/StoriesSlice";
import addAndRemoveToStories from "src/utils/addAndRemoveToStories";

class AddToStoryButton extends Component {
  constructor(props) {
    super(props);

    bindAll(
      this,
      "handleClick",
      "isAddedToEveryStories",
      "isARecordAddInProgress",
      "isProcessing",
      "storiesLinkedToRecord",
      "storiesToAlwaysAdd"
    );
  }

  isARecordAddInProgress() {
    return !isEmpty(this.props.processingRecordIds);
  }

  isProcessing() {
    return includes(this.props.processingRecordIds, this.props.record.id);
  }

  storiesLinkedToRecord() {
    return filter(this.props.stories, (story) =>
      some(story.recordIds, { recordId: this.props.record.id })
    );
  }

  storiesToAlwaysAdd() {
    return filter(this.props.stories, (story) =>
      includes(this.props.storyIdsToAlwaysAdd, story.id)
    );
  }

  handleClick() {
    const { alwaysAddToStory, storyIdsToAlwaysAdd, record, stories, dispatch } =
      this.props;
    if (alwaysAddToStory) {
      // converts from [1, 2, 3] to {1: true, 2: true, 3: true}
      const storiesMap = Object.assign(
        ...storyIdsToAlwaysAdd.map((k) => ({ [k]: true }))
      );
      addAndRemoveToStories({ record, storiesMap, stories, dispatch });
      dispatch(openNotification());
    } else {
      dispatch(closeNotification());
      dispatch(updateActiveRecord(record));
    }
  }

  isAddedToEveryStories(storyIds) {
    const storiesLinkedToRecordIds = map(this.storiesLinkedToRecord(), "id");

    return isEqual(intersection(storyIds, storiesLinkedToRecordIds), storyIds);
  }

  render() {
    const {
      storyIdsToAlwaysAdd,
      alwaysAddToStory,
      isExpanded,
      className,
      buttonClass,
    } = this.props;
    let buttonModifier = `${buttonClass}--add`,
      addStoryText = "Add to a story";

    if (alwaysAddToStory) {
      // if you have the always add flag on and the record is already part of the selected stories
      if (this.isAddedToEveryStories(storyIdsToAlwaysAdd)) {
        addStoryText = `Added to ${
          storyIdsToAlwaysAdd.length > 1 ? "stories" : "story"
        }`;
        buttonModifier = `${buttonClass}--added`;
      } else {
        // if you have the always add flag on and you can add to the stories
        addStoryText = (
          <AddToStoryDescription
            preContent="Add to"
            storyNames={map(this.storiesToAlwaysAdd(), "name")}
          />
        );
      }
    } else if (this.storiesLinkedToRecord().length > 0) {
      // if you don't always add you already have stories linked to the record
      addStoryText = `Added to ${
        this.storiesLinkedToRecord().length > 1 ? "stories" : "story"
      }`;
      buttonModifier = `${buttonClass}--added`;
    }

    if (this.isProcessing()) {
      return (
        <button
          className={classNames(
            className,
            buttonClass,
            `${buttonClass}--processing`
          )}
        >
          <i className="fa fa-circle-o-notch fa-spin start"></i>
          {`Adding to ${
            this.storiesToAlwaysAdd().length > 1 ? "stories" : "story"
          }`}
        </button>
      );
    } else {
      return (
        <button
          onClick={this.handleClick}
          className={classNames(className, buttonClass, buttonModifier)}
          aria-haspopup="true"
          aria-expanded={isExpanded}
          role="button"
          disabled={
            (alwaysAddToStory &&
              this.isAddedToEveryStories(storyIdsToAlwaysAdd)) ||
            this.isARecordAddInProgress()
          }
        >
          {addStoryText}
        </button>
      );
    }
  }
}

export default connect((state) => ({
  stories: selectAllStories(state),
  alwaysAddToStory: selectAddToStory(state).alwaysAddToStory,
  storyIdsToAlwaysAdd: selectAddToStory(state).storyIdsToAlwaysAdd,
  processingRecordIds: selectAddToStory(state).processingRecordIds,
  isExpanded: selectAddToStory(state).modalIsOpened,
}))(AddToStoryButton);
