import { filter, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { snakeToCamelCase } from "src/utils/keyConverters";
import AddToStoryModalFactory from "./AddToStoryModal/AddToStoryModalFactory";

import {
  closeModal as closeModalAction,
  openNotification,
  selectAddToStory,
  setModalIsProcessing,
  setStoryIdsToAlwaysAdd,
  unsetModalIsProcessing,
} from "src/features/AddToStorySlice";
import { blockAdd } from "src/features/ItemsSlice";
import {
  addItemToStory,
  addStory,
  createStory,
  selectAllStories,
} from "src/features/StoriesSlice";
import { selectUser } from "src/features/UserSlice";
import addAndRemoveToStories from "src/utils/addAndRemoveToStories";

// This was originally below the mounted apps that provide the toggle button to display the dialog
// But I realized when implementing the default story functionality that those apps themselves
// need to have the logic required to add an item to a story because the dialog toggle button
// does that if defaultStory is set.
//
// So I restructured the hierachy to put this at the top as an HOC that transparently
// handles the defaultStory functionality as part of the toggle function provided by the Openable HOC
// and deals with the construction of the Dialog itself which is passed down as a prop
const AddToStoryModalBehaviour = () => {
  const dispatch = useDispatch();
  const stories = useSelector(selectAllStories);
  const { loggedIn } = useSelector(selectUser);
  const { modalIsOpened, activeRecord, modalIsProcessing } =
    useSelector(selectAddToStory);

  const closeModal = async () => {
    await dispatch(closeModalAction());
  };

  const boundCreateStory = async (name) => {
    dispatch(setModalIsProcessing());
    const response = await dispatch(
      createStory({
        name: name,
        type: "embed",
        subType: "record",
        content: activeRecord,
      })
    );

    const newStory = snakeToCamelCase(response.payload);
    newStory.recordIds = [];
    newStory.numberOfItems = 0;

    await dispatch(addStory(newStory));
    await dispatch(
      blockAdd({
        storyId: newStory.id,
        type: "embed",
        subType: "record",
        content: activeRecord,
      })
    );

    await dispatch(
      addItemToStory({ storyId: newStory.id, storyItem: activeRecord })
    );

    dispatch(unsetModalIsProcessing());
    closeModal();

    dispatch(setStoryIdsToAlwaysAdd([newStory.id]));
    dispatch(openNotification());
  };

  const boundAddRemoveToStories = async (storiesMap, record) => {
    dispatch(setModalIsProcessing());
    await addAndRemoveToStories({ record, storiesMap, stories, dispatch });

    dispatch(unsetModalIsProcessing());
    await closeModal();

    // select only the added IDs from the storiesMap
    const addedIds = map(
      filter(Object.entries(storiesMap), (o) => o[1]),
      (o) => o[0]
    );
    dispatch(setStoryIdsToAlwaysAdd(addedIds));
    if (addedIds.length > 0) {
      dispatch(openNotification());
    }
  };

  return (
    <AddToStoryModalFactory
      toggleOpenCb={closeModal}
      storyCreateCb={boundCreateStory}
      isOpened={modalIsOpened}
      boundAddRemoveToStories={boundAddRemoveToStories}
      loggedIn={loggedIn}
      stories={stories}
      record={activeRecord}
      loading={modalIsProcessing}
    />
  );
};

export default AddToStoryModalBehaviour;
