// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import { init as initApm } from "@elastic/apm-rum";
import "core-js";
import "delayed-scroll-restoration-polyfill";
import $ from "jquery";
import "regenerator-runtime/runtime";
import Analytics from "src/utils/Analytics";
import InputCounter from "src/utils/inputCounter";
import "url-polyfill";
import "whatwg-fetch";

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

//

initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: "DigitalNZ",

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: "https://apm-server.digitalnz.org",

  // Set service version (required for sourcemap feature)
  serviceVersion: "",

  active: false,
  // to enable this again
  // active:
  //   process.env.RAILS_ENV == "staging" || process.env.RAILS_ENV == "production",
  environment: process.env.RAILS_ENV,
  ignoreTransactions: [new RegExp(".+XFBML.+")], // ignore errors from the facebook widget
});

$(function () {
  InputCounter.init();
});

Analytics.init();
