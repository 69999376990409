import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { assign, filter, find, isEqual, merge, remove, sortBy } from "lodash";
import generateSearchParams from "src/utils/generateSearchParams";
import { request } from "src/utils/request";

export const fetchMoreSearchResults = createAsyncThunk(
  "search/fetchMoreSearchResultsStatus",
  async (page, { getState }) => {
    const SEARCH_ENDPOINT = "/records.json?";

    if (page < 1) {
      page = 1;
    }

    if (page > 100) {
      page = 100;
    }

    let { query, filters, activeTab, layout } = getState().ui.search;

    const searchParams = assign(
      {},
      merge(generateSearchParams(query, filters, activeTab, layout), {
        page: page,
      })
    );

    const response = await request({
      url: SEARCH_ENDPOINT + $.param(searchParams),
    }).then((req) => req.json());
    return response;
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    activeRecord: null,
    previouslyActiveRecord: null,
    page: 1,
    results: [],
    loading: false,
    apiAvailable: true,
    mobileFiltersState: "closed",
    panel: {
      activeFacetTab: null,
    },
    layout: "grid",
  },
  reducers: {
    switchLayoutClick(state, action) {
      state.layout = action.payload;
    },
    updateSearchQuery(state, action) {
      state.query = action.payload;
    },
    selectTab(state, action) {
      state.activeTab = action.payload;
    },
    toggleFacet(state, action) {
      state.refreshWithNewFilters =
        action.payload.refreshWithNewFilters || false;
      if (find(state.filters, action.payload.filter)) {
        remove(state.filters, action.payload.filter);
      } else {
        state.filters.push(action.payload.filter);
      }

      state.hasDifferentFilters = !isEqual(
        sortBy(state.initialFilters, "value"),
        sortBy(state.filters, "value")
      );
    },
    clearFacet(state, action) {
      remove(state.filters, function (f) {
        if (action.payload.facet == "concept_ids") {
          return (
            f.facet == action.payload.facet && f.value == action.payload.value
          );
        } else if (action.payload.facet == "subject") {
          return (
            f.facet == action.payload.facet && f.value == action.payload.value
          );
        } else {
          return f.facet == action.payload;
        }
      });

      state.hasDifferentFilters = !isEqual(
        sortBy(state.initialFilters, "value"),
        sortBy(state.filters, "value")
      );
    },
    clearAllFacets(state, action) {
      state.filters = [];
      state.refreshWithNewFilters =
        action.payload?.refreshWithNewFilters || false;

      state.hasDifferentFilters = !isEqual(
        sortBy(state.initialFilters, "value"),
        sortBy(state.filters, "value")
      );
    },
    clearFiltersByFacet(state, action) {
      state.filters = filter(
        state.filters,
        (filter) => filter.facet !== action.payload
      );

      state.hasDifferentFilters = !isEqual(
        sortBy(state.initialFilters, "value"),
        sortBy(state.filters, "value")
      );
    },
    toggleFacetTab(state, action) {
      const facetName = action.payload?.facetName;
      state.refreshWithNewFilters =
        action.payload?.refreshWithNewFilters || false;

      if (state.panel.activeFacetTab == facetName) {
        state.panel.activeFacetTab = null;
      } else {
        state.panel.activeFacetTab = facetName;
      }
    },
    // Mobile filters
    openMobileFilters(state) {
      state.mobileFiltersState = "opened";
    },
    closeMobileFilters(state) {
      state.mobileFiltersState = "closed";
    },
    setMobileFiltersState(state, action) {
      state.mobileFiltersState = action.payload;
    },
    updateDateRange(state, action) {
      if (
        find(state.filters, (filter) => {
          return filter.facet == "date_range";
        })
      ) {
        remove(state.filters, (filter) => {
          return filter.facet == "date_range";
        });
      }

      state.filters.push(action.payload);

      state.hasDifferentFilters = !isEqual(
        sortBy(state.initialFilters, "value"),
        sortBy(state.filters, "value")
      );
    },
    toggleError(state, action) {
      state.panel.errors[action.payload.field] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMoreSearchResults.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(fetchMoreSearchResults.fulfilled, (state, action) => {
        state.active = null;
        state.loading = false;

        state.page = action.payload.page;
        state.per_page = action.payload.per_page;
        state.total = action.payload.total;

        state.resultsIds = [...action.payload.records.map((r) => r.id)];
      });
  },
});

export const selectSearch = (state) => state.ui.search;

const { actions, reducer } = searchSlice;
export const {
  switchLayoutClick,
  updateSearchQuery,
  closePanel,
  selectTab,
  toggleFacet,
  clearFacet,
  clearAllFacets,
  toggleFacetTab,
  clearFiltersByFacet,
  openMobileFilters,
  closeMobileFilters,
  setMobileFiltersState,
  updateDateRange,
  toggleError,
} = actions;

export default reducer;
