import AddToStoryButton from "src/components/AddToStoryButton/AddToStoryButton";

import { truncate } from "lodash";
import { useDispatch } from "react-redux";
import {
  setEditingUserImage,
  openEditUserImageModal,
} from "src/features/EditUserImageSlice";
import Analytics from "src/utils/Analytics";

const ReactActions = (props) => {
  const dispatch = useDispatch();

  const handleEditDetailsClick = () => {
    dispatch(setEditingUserImage(props.record.id));
    dispatch(openEditUserImageModal());
  };

  return (
    <>
      {props.record.displayCollection !== "User Uploaded Content" && (
        <a
          className="button margin-bottom-1 d-print-none"
          href={props.record.sourceUrl}
          ga-category="Click Through"
          ga-action="Landing page - View button"
          ga-label={truncate(props.record.sourceUrl, { length: 125 })}
          onClick={Analytics.onClick}
        >
          {props.record.category[0] === "Audio" ? "Listen to" : "View"} original
          item<i aria-hidden="true" className="fa fa-external-link end"></i>
        </a>
      )}

      {props.record.belongsToUser && (
        <button
          className="button margin-bottom-1 d-print-none"
          onClick={handleEditDetailsClick}
        >
          Edit details
        </button>
      )}
      <br />
      {(props.record.displayCollection !== "User Uploaded Content" ||
        props.record.belongsToUser) && (
        <AddToStoryButton
          {...props}
          className="margin-bottom-2 d-print-none"
          buttonClass="ats-button-record"
        />
      )}
    </>
  );
};

export default ReactActions;
