import { Component } from "react";
import Modal from "src/components/Modal";
import { bindAll, map } from "lodash";

const privacyTextMap = [
  { text: "Public (Viewable by anyone)", value: "public" },
  { text: "Hidden (Viewable only with URL)", value: "hidden" },
  { text: "Private (Viewable by you only)", value: "private" },
];

class RightsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyState: props.privacy,
      shareModal: false,
      publishModal: false,
      publicModal: false,
      storyState: props.state,
      storyName: props.name,
    };

    bindAll(
      this,
      "updateAccessRight",
      "saveRights",
      "closeModal",
      "openModal",
      "updatePrivacy"
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.privacy !== this.state.privacyState) {
      this.setState({
        privacyState: nextProps.privacy,
      });
    }
  }

  updateAccessRight(e) {
    this.setState({
      privacyState: e.target.value,
    });
  }

  resetForm() {
    this.closeModal("shareModal");
  }

  openModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  closeModal(modal, resetForm = false) {
    this.setState({
      [modal]: false,
      privacyState: resetForm ? this.props.privacy : this.state.privacyState,
    });
  }

  saveRights() {
    const { privacyState } = this.state;

    if (privacyState === "public" && this.props.storyTextBlockCount > 0)
      this.openModal("publishModal");
    else this.updatePrivacy();

    this.closeModal("shareModal");
  }

  updatePrivacy() {
    const { updateCb } = this.props;
    const { privacyState } = this.state;

    updateCb("privacy", privacyState);
    this.closeModal("publishModal");
    if (privacyState === "public") this.openModal("publicModal");
  }

  canBePublic() {
    return this.props.numberOfItems > 2 || this.props.storyTextBlockCount !== 0;
  }

  accessDisplayClassFor(storyState) {
    if (this.canBePublic()) {
      if (
        storyState == "unmoderated" &&
        this.state.privacyState !== "private" &&
        this.state.storyName !== "Favourites"
      ) {
        return "story-header-edit__content__access__description--unmoderated";
      } else {
        return "story-header-edit__content__access__description";
      }
    } else {
      return "story-header-edit__content__access__description";
    }
  }

  accessDisplayTextFor(storyState) {
    if (this.canBePublic()) {
      if (
        storyState == "unmoderated" &&
        this.state.privacyState !== "private" &&
        this.state.storyName !== "Favourites"
      ) {
        return `This story is currently in moderation and will be made ${this.state.privacyState} if approved.`;
      } else {
        return `This story is ${this.state.privacyState}`;
      }
    } else {
      return "To be able to Publicly share this story, please add more items or text.";
    }
  }

  manageAccessButton() {
    let button = this.canBePublic() ? (
      <button
        id="change-story-access-rights"
        className="story-header-edit__content__access__button"
        type="submit"
        onClick={() => this.openModal("shareModal")}
        aria-haspopup="true"
        aria-controls="access-form"
        aria-label="change story access"
      >
        Manage/change access
      </button>
    ) : (
      ""
    );

    return button;
  }

  manageAccess() {
    return (
      <p className={this.accessDisplayClassFor(this.props.state)}>
        {this.manageAccessButton()}{" "}
        {this.accessDisplayTextFor(this.props.state)}
      </p>
    );
  }

  rejectedMessage() {
    return (
      <p className="story-header-edit__content__access__description--rejected">
        <strong>
          This story is rejected because we believe it has violated DigitalNZ
          contributed content terms of use. If you believe our action is
          incorrect please get in touch with us by emailing{" "}
          <a href="mailto:info@digitalnz.org">info@digitalnz.org</a>.
        </strong>
      </p>
    );
  }

  displayAccessState() {
    if (this.state.storyState === "rejected") {
      return this.rejectedMessage();
    } else {
      return this.manageAccess();
    }
  }

  render() {
    const { privacyState, shareModal, publishModal, publicModal } = this.state;

    return (
      <div className="story-header-edit__content__access">
        {this.displayAccessState()}

        <Modal
          isOpen={shareModal}
          onRequestClose={() => this.closeModal("shareModal", true)}
          className="share-modal generic-modal"
          title="Sharing settings"
        >
          <div className="generic-modal__content">
            <p id="sharing-description">Select who can see your stories</p>
            <div>
              {map(privacyTextMap, (right) => (
                <div key={right.value}>
                  <input
                    type="radio"
                    name="privacy"
                    value={right.value}
                    id={`privacy-${right.value}`}
                    checked={privacyState === right.value}
                    onChange={this.updateAccessRight}
                  />
                  <label
                    htmlFor={`privacy-${right.value}`}
                    id={`privacy-${right.value}-label`}
                  >
                    {right.text}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button clear"
              aria-label="Close modal"
              id="close-modal"
              onClick={() => this.closeModal("shareModal", true)}
            >
              Cancel
            </button>
            <button
              className="button"
              aria-label="Close modal"
              onClick={() => this.saveRights()}
              id="update-story-access"
            >
              Save
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={publishModal}
          onRequestClose={() => this.closeModal("publishModal", true)}
          className="generic-modal"
          title="Publish your story"
        >
          <div className="generic-modal__content">
            <p>
              By publishing a story on DigitalNZ, you agree to our{" "}
              <a href="/about/terms-of-use#contributedcontent" target="_blank">
                terms and conditions
              </a>{" "}
              and release your story under a{" "}
              <a
                href="https://creativecommons.org/licenses/by/4.0/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Creative Commons CC-BY license
              </a>
            </p>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button clear"
              aria-label="Close modal"
              id="dont-delete-story-publish-modal"
              onClick={() => this.closeModal("publishModal", true)}
            >
              Keep private
            </button>
            <button
              className="button"
              aria-label="Close modal"
              onClick={() => this.updatePrivacy()}
            >
              Publish
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={publicModal}
          onRequestClose={() => this.closeModal("publicModal")}
          className="generic-modal"
          id="confirm-public-modal"
          title="Story is public"
        >
          <div className="generic-modal__content">
            <p>
              Any changes you make will be immediately visible to anyone reading
              this story. You can make your story private by changing story
              privacy settings at the top of this page.
            </p>
          </div>
          <hr />
          <div className="generic-modal__actions">
            <button
              className="button"
              aria-label="Close modal"
              onClick={() => this.closeModal("publicModal")}
            >
              OK, got it
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RightsForm;
