import {
  assign,
  find,
  first,
  groupBy,
  isNil,
  map,
  merge,
  omit,
  reduce,
} from "lodash";

export default function generateSearchParams(query, filters, activeTab, page) {
  let baseQueryParams = { text: query, i: {}, or: {}, page: page || 1 };

  const dateRange = find(filters, (filter) => {
    return filter.facet == "date_range";
  });

  if (dateRange) {
    const years = dateRange.value.split(" TO ");
    const fromYear = years[0];
    const toYear = years[1];

    baseQueryParams.from_year = fromYear;
    baseQueryParams.to_year = toYear;
  }

  // This groups all the filters by what facet they belong to so we can
  // determine whether there are multiple for a facet to determine whether
  // they need to be an 'i' or 'or' parameter
  let groupedFacets = groupBy(filters, "facet");
  groupedFacets = omit(groupedFacets, "date_range");

  if (activeTab !== "All" && !isNil(activeTab)) {
    assign(baseQueryParams, { tab: activeTab });
  }

  // This reduces the object of grouped facets, which looks like this
  // { content_partner: [ { facet: 'content_partner', value: '95bFM' } ], ... }
  // into the baseQueryParams object.
  //
  // Assigning the filter to either the 'i' or 'or' params depending on whether
  // or not there are multiple filters for a given facet
  const fullQueryParams = reduce(
    groupedFacets,
    (acc, group) => {
      if (group.length === 1) {
        const facet = first(group);
        return assign({}, merge(acc, { i: { [facet.facet]: facet.value } }));
      }

      const values = map(group, "value");
      const facet = first(group).facet;

      return assign({}, merge(acc, { or: { [facet]: values } }));
    },
    baseQueryParams
  );

  // This uses the jquery param method to convert an object into properly
  // url encoded query parameters
  return fullQueryParams;
}
