import classNames from "classnames";
import { bindAll, filter, map, some } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";

import {
  clearFacet,
  openMobileFilters,
  selectTab,
  toggleFacet,
} from "src/features/SearchSlice";

import DateRangeFilter from "src/components/DesktopFilter/DateRangeFilter";
import { validDateRangeFilter } from "src/utils/search";

class FilterList extends Component {
  constructor(props) {
    super(props);
    bindAll(
      this,
      "displayFilters",
      "applyFilters",
      "activeFilter",
      "activeFacetsInPanel",
      "onUpdateFilterClick"
    );
  }

  applyFilters(filter) {
    if (this.props.facet == "format") {
      this.props.dispatch(selectTab(filter));
    } else {
      const facet = this.props.facet;
      this.props.dispatch(toggleFacet({ filter: { facet, value: filter } }));
    }
  }

  activeFilter(filter) {
    return (
      this.props.ui.search.activeTab == filter ||
      some(
        this.props.ui.search.filters,
        (f) => f.facet === this.props.facet && f.value === filter
      )
    );
  }

  displayFilters() {
    return (
      <ul>
        {map(this.props.filters, (count, filter) => {
          const listClass = classNames({
            "mobile-filters__options__option": true,
            "mobile-filters__options__option--active":
              this.activeFilter(filter),
          });

          const filterItem = (
            <li
              onClick={() => {
                this.applyFilters(filter);
              }}
              key={filter}
              className={listClass}
            >
              <div className="grid-x">
                <div className="cell small-1 mobile-filters__options__option__checkbox">
                  {this.props.multiple && (
                    <input
                      onChange={() => {}}
                      type="checkbox"
                      checked={this.activeFilter(filter)}
                    ></input>
                  )}
                </div>

                <div className="cell small-8">{`${filter}${
                  this.props.name === "Decades" ? "s" : ""
                }`}</div>
                <div className="cell small-3 mobile-filters__options__option__count">
                  {count.toLocaleString()}
                </div>
              </div>
            </li>
          );

          return filterItem;
        })}
      </ul>
    );
  }

  activeFacetsInPanel() {
    return (
      filter(this.props.ui.search.filters, { facet: this.props.facet }).length >
        0 && this.props.facet != "format"
    );
  }

  onUpdateFilterClick() {
    if (validDateRangeFilter(this.props.dispatch, this.props.ui.search)) {
      this.props.dispatch(openMobileFilters());
    }
  }

  render() {
    return (
      <div className="mobile-filters">
        <div className="mobile-filters__nav">
          <button
            onClick={() => this.props.dispatch(openMobileFilters())}
            className="mobile-filters__nav__back"
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            Back
          </button>

          {this.activeFacetsInPanel() && (
            <span
              className="mobile-filters__clear-all"
              onClick={() => {
                this.props.dispatch(clearFacet(this.props.facet));
              }}
            >
              Reset filters
            </span>
          )}

          <a className="mobile-filters__nav__logo" href="/" />
        </div>

        <h1 className="mobile-filters__heading mobile-filters__heading--active">
          {this.props.name}
        </h1>

        <div className="mobile-filters__options">
          {this.props.facet != "date_range" && this.displayFilters()}
          {this.props.facet == "date_range" && (
            <DateRangeFilter
              enterCallback={this.onUpdateFilterClick}
              enterCallbackType="action"
            />
          )}
        </div>

        <button
          onClick={() => this.onUpdateFilterClick()}
          className="mobile-filters__button"
        >
          UPDATE FILTERS
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(FilterList);
