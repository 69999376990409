import { replace } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import AddToStoryModalBehaviour from "src/components/AddToStoryModalBehaviour";
import DesktopFilter from "src/components/DesktopFilter/index";
import MobileFilters from "src/components/MobileFilters/index";
import NoResults from "src/components/SearchPage/components/search/NoResults";
import SearchResults from "src/components/SearchPage/components/search/SearchResults";
import SearchBox from "src/components/SearchPage/components/searchBox/SearchBox";
import { selectSearch } from "src/features/SearchSlice";
import Analytics from "src/utils/Analytics";
import generateTitleText from "src/utils/generateTitleText";
import { performSearch } from "src/utils/search";
import AdvancedSearch from "./components/searchBox/AdvancedSearch";
import SearchTab from "./components/searchTab/index";

const SearchPage = () => {
  const dispatch = useDispatch();
  const [searchMode, setSearchMode] = useState("simple");
  const search = useSelector(selectSearch);

  useEffect(() => {
    // This sneaky code gets the browser to auto scroll to the id the user was viewing
    // we strip the slash so that it plays nice with the mobile filters router.
    const item_id = replace(location.hash, "/", "");

    location.hash = "";

    // TODO the transition seems to be too fast for the render.
    setTimeout(function () {
      location.hash = item_id;
    }, 10);
  }, []);

  if (search.resultsIds === undefined) {
    location.reload();
    return;
  }

  const resultsLength = search.resultsIds.length;

  const titleText = () => {
    let filters = search.filters.slice();
    let searchTab;

    if (search.activeTab !== "All") {
      searchTab = search.activeTab;
    }

    return generateTitleText(search.query, "SEARCH", filters, searchTab);
  };

  const switchSearchMode = (e) => {
    e.preventDefault();

    setSearchMode(searchMode === "simple" ? "advanced" : "simple");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    Analytics.event(
      "Search",
      "Search Box",
      $(event.target).find("input").filter(":visible:first").val()
    );

    performSearch(dispatch, search);
  };

  if (search.resultsIds === undefined) return;

  if (search.apiAvailable) {
    return (
      <>
        <Helmet>
          <title>{titleText()}</title>
        </Helmet>

        <div className="search-container">
          {search.mobileFiltersState == "closed" &&
            ((searchMode === "simple" && (
              <SearchBox
                handleSubmit={handleSubmit}
                switchSearchMode={switchSearchMode}
              />
            )) || <AdvancedSearch />)}
          <SearchTab />

          {resultsLength > 0 && <MobileFilters />}
        </div>

        {search.mobileFiltersState != "closed" && (
          <div className="mobile-filters-background"></div>
        )}

        {resultsLength > 0 && <DesktopFilter />}
        {resultsLength > 0 && search.mobileFiltersState == "closed" && (
          <SearchResults />
        )}

        {resultsLength === 0 && (
          <NoResults
            categories={[
              ...search.primaryCategoryCounts,
              ...search.secondaryCategoryCounts,
            ]}
            activeCategory={search.activeTab}
            currentSearch={search.query}
            filters={search.filters}
          />
        )}
        <AddToStoryModalBehaviour />
      </>
    );
  } else {
    return (
      <div className="grid-container">
        <strong>
          Sorry, search is not working at the moment. Someone has been notified.
          Please try again later.
        </strong>
      </div>
    );
  }
};

export default SearchPage;
