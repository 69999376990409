import DNZRecord from "./DNZRecord";

const subtypeMappings = {
  record: DNZRecord,
};

export const EmbedBlock = (props) => {
  const { subType } = props;
  const Component = subtypeMappings[subType];

  return <Component {...props} />;
};
