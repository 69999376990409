import { configureStore } from "@reduxjs/toolkit";

export default function configureAppStore(reducer, preloadedState) {
  const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    preloadedState,
  });

  return store;
}
