import { useCallback, useRef, useState } from "react";
import Modal from "src/components/Modal";
import { isEmpty } from "lodash";

const LinkModal = ({
  linkModal,
  editor,
  setLinkModal,
  linkHref,
  setLinkHref,
  linkExternal,
  setLinkExternal,
}) => {
  let linkInput = useRef();
  let [error, setError] = useState(false);

  const checkHandler = () => {
    setLinkExternal(!linkExternal);
  };

  const setLink = useCallback(() => {
    if (
      linkInput.current.checkValidity() &&
      !isEmpty(linkInput.current.value)
    ) {
      setError(false);
      let linkTarget = null;

      if (linkExternal) {
        linkTarget = "_blank";
      }

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: linkHref, target: linkTarget })
        .run();

      setLinkModal(false);
      setLinkHref("");
      setLinkExternal(false);
    } else {
      setError(true);
    }
  }, [
    setLinkExternal,
    setLinkHref,
    editor,
    linkHref,
    setLinkModal,
    linkExternal,
  ]);

  return (
    <Modal
      isOpen={linkModal}
      onRequestClose={() => setLinkModal(false)}
      className="share-modal generic-modal"
      title="Add link"
    >
      <div className="generic-modal__content">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <input
              ref={linkInput}
              type="url"
              placeholder="https://www.example.com"
              onChange={(e) => {
                setLinkHref(e.target.value);
              }}
              value={linkHref}
            />

            <input
              className="tip-tap__external-link"
              id="external-link"
              type="checkbox"
              checked={linkExternal}
              onChange={checkHandler}
            />
            <label for="external-link">Open in a new tab</label>
          </div>
        </div>

        {error && <p className="error">Please enter a valid URL</p>}
      </div>
      <hr />
      <div className="generic-modal__actions">
        <button
          className="button clear"
          aria-label="Cancel"
          onClick={() => setLinkModal(false)}
        >
          Cancel
        </button>

        <button
          className="button"
          aria-label="Cancel"
          onClick={() => setLink()}
          type="submit"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default LinkModal;
