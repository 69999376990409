import inBrowser from "./inBrowser";

export default function findGetParameter(parameterName, search = null) {
  if (search === null && !inBrowser()) return null;

  const parametersString = search == null ? window.location.search : search;
  let result = null,
    tmp = [];
  const items = parametersString.substr(1).split("&");
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}
