import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getEndDate } from "./FiltersSlice";
import { fetchMetrics } from "./MetricsSlice";

const globalMetricsSlice = createSlice({
  name: "globalMetrics",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetrics.pending, (state, _) => {
        state.loading = true;
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.loading = false;

        const globalMetrics = action.payload.globalMetrics;
        for (const key of Object.keys(globalMetrics)) {
          state[key] = globalMetrics[key];
        }
      })
      .addCase(fetchMetrics.rejected, (state, _) => {
        state.loading = false;
      });
  },
});

export const selectGlobalMetrics = (state) => state.globalMetrics;

export const selectedTotalSets = createSelector(
  [getEndDate, selectGlobalMetrics],
  (endDate, globalMetrics) => {
    if (globalMetrics.loading) return 0;

    return globalMetrics[endDate]
      ? globalMetrics[endDate].total_public_sets
      : "Unavailable";
  }
);

const { reducer } = globalMetricsSlice;

export default reducer;
