import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const plugFeaturesAdapter = createEntityAdapter();

const plugFeatureSlice = createSlice({
  name: "plugFeatures",
  initialState: {},
  reducers: {},
});

const { reducer } = plugFeatureSlice;

export const {
  selectById: selectPlugFeatureById,
  selectIds: selectPlugFeatureIds,
  selectEntities: selectPlugFeatureEntities,
  selectAll: selectAllPlugFeatures,
  selectTotal: selectTotalPlugFeatures,
} = plugFeaturesAdapter.getSelectors((state) => state.entities.plugFeatures);

export const selectPlugFeatureBySlug = (state, slug) => {
  return selectAllPlugFeatures(state).find((feature) => {
    return feature.slug === slug;
  });
};

export const featureIsEnabled = (state, slug) => {
  const feature = selectPlugFeatureBySlug(state, slug);

  return feature === undefined || feature.state === "enabled";
};

export const featureIsDisabled = (state, feature) => {
  return !featureIsEnabled(state, feature);
};

export default reducer;
