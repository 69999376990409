import MediaInRecordPage from "src/components/MediaInRecordPage";

import InformationImage from "images/information.png";

const MoreInformation = ({
  displayContentPartner,
  rightsInformation,
  rightsUrl,
}) => {
  if (!(rightsUrl && rightsUrl.length > 0) && !rightsInformation) {
    return "";
  }

  return (
    <MediaInRecordPage
      img_src={InformationImage}
      img_alt="Information"
      title="More Information"
    >
      {rightsInformation && (
        <>
          <p>
            {displayContentPartner} has this to say about the rights status of
            this item:
          </p>
          <p>{rightsInformation}</p>
        </>
      )}

      {rightsUrl && rightsUrl.length > 0 && (
        <p>
          You can learn more about the rights status of this item at:{" "}

          { rightsUrl.length == 1 && (
            <a href={rightsUrl} target="_blank">
              {rightsUrl}
            </a>
          )}

          { rightsUrl.length > 1 && (
            <ul className='margin-top-1'>
              {
                rightsUrl.map((url) => {
                  return(
                    <li>
                      <a href={url} target="_blank">
                        {url}
                      </a> 
                    </li>
                  )
                })
              }
            </ul>
          )}
        </p>
      )}
    </MediaInRecordPage>
  );
};

export default MoreInformation;
