// MODULES
import { isNil, keys } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import DateRange from "./DateRange";
import DownloadCsv from "./DownloadCsv";
import FacetSelector from "./FacetSelector";
import IndexPage from "./IndexPage";

import SpinnerImage from "images/spinner.gif";
import generateDateRange from "src/utils/generateDateRange";

// SLICES
import { selectFacets } from "src/features/Dashboard/FacetsSlice";
import {
  selectFilters,
  selectNewDateRange,
  selectNewFacet,
} from "src/features/Dashboard/FiltersSlice";
import {
  fetchMetrics,
  selectMetrics,
} from "src/features/Dashboard/MetricsSlice";

const shouldFetchMetrics = (metrics, facet, startDate, endDate) => {
  const dateRangesArray = generateDateRange(startDate, endDate);
  const metricsForFacet = metrics[facet];

  if (isNil(metricsForFacet)) return true;
  if (metricsForFacet.loading) return false;

  const currentMetricsDates = keys(metricsForFacet);
  return dateRangesArray.some((date) => !currentMetricsDates.includes(date));
};

const Main = () => {
  const dispatch = useDispatch();
  const { startDate, endDate, selectedFacet } = useSelector(selectFilters);
  const metrics = useSelector(selectMetrics);
  const facets = useSelector(selectFacets);

  const selectedMetricObject = metrics[selectedFacet];
  const loading = selectedMetricObject ? selectedMetricObject.loading : true;

  useEffect(() => {
    if (shouldFetchMetrics(metrics, selectedFacet, startDate, endDate)) {
      dispatch(
        fetchMetrics({
          facet: selectedFacet,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  }, [dispatch, metrics, selectedFacet, startDate, endDate]);

  const loadingText = (
    <div className="dashboard-loading">
      <h1>Loading data</h1>
      <img src={SpinnerImage} alt="Spinner" />
    </div>
  );

  const changeSelectedFacet = (facet) => {
    dispatch(selectNewFacet(facet));
  };

  const changeSelectedDateRange = (dateRange) => {
    dispatch(selectNewDateRange(dateRange));
  };

  return (
    <div className="grid-container dashboard-container margin-top-2">
      <h1 id="title">Dashboard</h1>
      <p className="intro-blurb">
        Welcome to DigitalNZ's metrics dashboard. Here you can find metrics
        about the different collections DigitalNZ brings together.
      </p>
      <div className="dashboard-filters grid-x grid-margin-x">
        <div className="cell small-12 medium-12 large-6">
          <FacetSelector
            action={changeSelectedFacet}
            values={facets}
            value={selectedFacet}
            label="Collection"
          />
        </div>
        <div className="cell small-12 medium-6 large-3">
          <DateRange action={changeSelectedDateRange} loading={loading} />
        </div>

        <div className="cell small-12 medium-6 large-3 end hide-for-small-only">
          {loading ? null : <DownloadCsv />}
        </div>
      </div>
      {loading ? loadingText : <IndexPage />}
    </div>
  );
};

export default Main;
