import AllRightsReserved from "./rights/AllRightsReserved";
import CreativeCommonsRights from "./rights/CreativeCommonsRights.jsx";
import OtherRights from "./rights/OtherRights";
import UnknownRights from "./rights/UnknownRights";

const Rights = (record) => {
  const rightsProps = {
    displayContentPartner: record.displayContentPartner,
    rightsInformation: record.rightsInformation,
    rightsUrl: record.rightsUrl,
    sourceUrl: record.sourceUrl,
    usage: record.usage,
  };

  let rightsElement;
  if (record.hasCreativeCommonsRights) {
    rightsElement = <CreativeCommonsRights {...record} />;
  } else if (record.usage.includes("All rights reserved")) {
    rightsElement = <AllRightsReserved {...rightsProps} />;
  } else if (record.usage.includes("Unknown")) {
    rightsElement = <UnknownRights {...rightsProps} />;
  } else {
    rightsElement = <OtherRights {...rightsProps} />;
  }

  return (
    <div
      className="record-section"
      id="copyright"
      aria-describedby="record-rights-label"
    >
      <h3 className="record-section__header">
        What is the copyright status of this item?
      </h3>
      {rightsElement}
    </div>
  );
};

export default Rights;
