import CopyrightLicence from "./CopyrightLicence";
import { Helmet } from "react-helmet";
import generateTitleText from "src/utils/generateTitleText";
import unescape from "lodash/unescape";
import { map, isEmpty } from "lodash";

const StoryHeader = ({ description, name, subjects, creator }) => {
  const subjectSpans = map(subjects, (subject) => (
    <a
      className="story-header__subject"
      href={`/records?i%5Bsubject_text%5D=${subject}`}
      key={subject}
    >
      {subject}
    </a>
  ));

  return (
    <div className="story-header">
      <Helmet>
        <title>{generateTitleText(name, "STORY")}</title>
      </Helmet>
      <div className="story-header__content">
        <h1 className="story-header__title">{unescape(name)}</h1>
        <p className="medium-6 story-header__creator">
          A DigitalNZ Story by {creator}
        </p>
        <p className="medium-6 story-header__text">{unescape(description)}</p>
        <p className="medium-6 story-header__text">
          {!isEmpty(subjectSpans) &&
            subjectSpans.reduce((prev, curr) => [prev, ", ", curr])}
        </p>
        <CopyrightLicence />
      </div>
    </div>
  );
};

export { StoryHeader };
