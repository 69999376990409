import classNames from "classnames";
import Downshift from "downshift";
import { useEffect, useState } from "react";

const filterSuggestions = (suggestions, inputText, maxResults = undefined) => {
  return suggestions
    .filter(
      (suggestion) =>
        suggestion.label.toLowerCase().indexOf(inputText.toLowerCase()) > -1
    )
    .slice(0, maxResults);
};

const findSelectedItem = (suggestions, value) => {
  return suggestions.find((suggestion) => suggestion.value === value) || null;
};

const Autocomplete = ({
  value,
  suggestions,
  onChange,
  maxResults,
  placeholder,
}) => {
  const selectedItem = findSelectedItem(suggestions, value);
  const [inputValue, setInputValue] = useState(selectedItem?.label || "");

  useEffect(() => {
    // Sync the input value when the selected item or value prop changes
    setInputValue(selectedItem?.label || "");
  }, [value, selectedItem]);

  return (
    <Downshift
      selectedItem={selectedItem}
      onInputValueChange={(inputVal) => setInputValue(inputVal)}
      onChange={(selection) => onChange({ target: { value: selection.value } })}
      itemToString={(item) => (item ? item.label : "")}
      inputValue={inputValue || ""}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        openMenu,
      }) => (
        <div className="autocomplete">
          <div {...getRootProps({}, { suppressRefError: true })}>
            <input
              type="text"
              className="search-row__field"
              placeholder={placeholder}
              onClick={openMenu}
              {...getInputProps()}
            />
          </div>
          <ul
            className={classNames("autocomplete__suggestions", {
              hide: !isOpen,
            })}
            {...getMenuProps()}
          >
            {isOpen
              ? filterSuggestions(suggestions, inputValue, maxResults).map(
                  (item, index) => (
                    <li
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        className: classNames("autocomplete__suggestion", {
                          active: highlightedIndex === index,
                        }),
                      })}
                    >
                      {item.value}
                    </li>
                  )
                )
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

export default Autocomplete;
