import AddToStoryModalBehaviour from "src/components/AddToStoryModalBehaviour";
import SearchResults from "src/components/SearchPage/components/search/SearchResults";

const SearchPageResultsOnly = () => {
  return (
    <>
      <SearchResults redirectToRecord={true} />
      <AddToStoryModalBehaviour />
    </>
  );
};

export default SearchPageResultsOnly;
