import inBrowser from "./inBrowser";

class Analytics {
  static init() {
    if (inBrowser()) {
      window.dataLayer = window.dataLayer || [];
      window.Analytics = Analytics;
    }

    Analytics._gtag("js", new Date());
    Analytics._gtag("config", Analytics._googleAnalytics4MeasurementId(), {
      send_page_views: true,
    });
  }

  static pageView() {
    Analytics._gtag("event", "page_view");
  }

  static event(action, category, label) {
    Analytics._gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  }

  static onClick(event) {
    const link = event.currentTarget;

    Analytics.event(
      link.getAttribute("ga-action"),
      link.getAttribute("ga-category"),
      link.getAttribute("ga-label")
    );
  }

  // private

  static _gtag() {
    if (inBrowser()) {
      window.dataLayer.push(arguments);
    }
  }

  static _googleAnalytics4MeasurementId() {
    if (!inBrowser()) return "";

    return document
      .querySelector("meta[name='google_analytics_4_measurement_id']")
      .getAttribute("content");
  }
}

export default Analytics;
