import { Component } from "react";

class ImageTermsOfUseForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.onValidTermsOfUseSubmit} className="licence">
        <div className="grid-x grid-margin-x generic-modal__content">
          <div className="cell medium-6 image-being-uploaded-box">
            <img src={this.props.image} className="image-being-uploaded" />
          </div>
          <div className="cell medium-6">
            <p>
              To complete your upload, please read and agree to our{" "}
              <a
                href="/about/terms-of-use/contributed-content-terms-of-use"
                target="_blank"
              >
                Contributed Content Terms of Use
              </a>
              .
            </p>
          </div>
        </div>

        <hr />
        <div className="generic-modal__actions">
          <button
            className="button clear"
            onClick={this.props.onBack}
            type="button"
          >
            Back
          </button>
          <button className="button" type="submit">
            Agree
          </button>
        </div>
      </form>
    );
  }
}

export default ImageTermsOfUseForm;
