import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-dates";
import { useSelector } from "react-redux";

import { selectFilters } from "src/features/Dashboard/FiltersSlice";

const DateRange = ({ action, loading }) => {
  moment.locale("en-NZ");
  const { startDate, endDate } = useSelector(selectFilters);
  const [dateRange, setDateRange] = useState({ startDate, endDate });

  const [focusedInput, setFocusedInput] = useState(null);

  const formatDateRange = (startDate, endDate) => {
    endDate = endDate === null ? startDate : endDate;

    return {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    };
  };

  const isOutsideRange = (day) => {
    const now = moment();

    return day.isAfter(now);
  };

  const initialVisibleMonth = () => {
    return moment(dateRange.startDate).subtract(1, "month");
  };

  return (
    <div className="date-range-picker">
      <label>Select Date Range</label>
      <DateRangePicker
        isOutsideRange={isOutsideRange}
        minimumNights={0}
        initialVisibleMonth={initialVisibleMonth}
        disabled={loading}
        startDate={moment(dateRange.startDate)}
        startDateId="startDate"
        phrases={{
          chooseAvailableStartDate: ({ date }) =>
            `Choose ${date} as the start date`,
          chooseAvailableEndDate: ({ date }) =>
            `Choose ${date} as the end date`,
        }}
        endDate={moment(dateRange.endDate)}
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => {
          // needed because rspec triggers the callback on each character
          if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) return;

          setDateRange(formatDateRange(startDate, endDate));
        }}
        onClose={({ startDate, endDate }) => {
          if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) return;

          setDateRange(formatDateRange(startDate, endDate));
          action({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
          });
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        orientation={window.innerWidth <= 640 ? "vertical" : "horizontal"}
        verticalHeight={680}
      />
    </div>
  );
};

export default DateRange;
