import { Component } from "react";
import AddToStoryButton from "src/components/AddToStoryButton/AddToStoryButton";
import EditOverlay from "src/components/RecordCard/EditOverlay";

function withMyUploadedItems(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const { id, recordId, title, thumbnailImageUrl } = this.props;

      return (
        <WrappedComponent
          key={id}
          title={title}
          category={[]}
          link={`/records/${recordId}`}
          id={recordId}
          externalLink={true}
          imageUrl={thumbnailImageUrl}
          children={
            <AddToStoryButton
              record={this.props}
              buttonClass="width-100 clear ats-button-record"
            />
          }
          editOverlay={<EditOverlay {...this.props} />}
        />
      );
    }
  };
}

export default withMyUploadedItems;
