import ReadMore from "src/components/ReadMore";

import { map, uniq, filter, truncate, includes } from "lodash";

import generateIconTitle from "src/utils/generateIconTitle";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";
import dnzNil from "src/utils/dnzNil";
import convertArray from "src/utils/convertArray";
import formatDate from "src/utils/formatDate";
import Analytics from "src/utils/Analytics";

function convertArrayItemsToSpans(array) {
  array = filter(uniq(array), (value) => !dnzNil(value));

  return convertArray(array, " / ");
}

function convertArrayItemsToLinks(array, queryType = "text") {
  array = filter(uniq(array), (value) => !dnzNil(value));

  const links = map(array, (arrayItem) => {
    return (
      <a href={`/records?${queryType}=${encodeURIComponent(arrayItem)}`}>
        {arrayItem}
      </a>
    );
  });

  return convertArray(links, " / ");
}

function Row({ attribute, children }) {
  if (!children) return null;
  if (children["$$typeof"] === undefined && dnzNil(children)) return null;

  return (
    <>
      <dt className="cell small-12 medium-3 record-details__attr">
        {attribute}
      </dt>
      <dd className="cell small-12 medium-9 record-details__value">
        {children}
      </dd>
    </>
  );
}

const RecordDetails = ({ record }) => {
  const creatorList =
    record.displayCollection === "User Uploaded Content"
      ? convertArrayItemsToSpans(record.creator)
      : convertArrayItemsToLinks(record.creator, "i[creator]");

  return (
    <div className="record-section">
      <h3 className="record-section__header">About this item</h3>
      <dl className="grid-x grid-margin-x record-details">
        <Row attribute="Title" children={unescapeXMLCharacters(record.title)} />

        {record.displayCollection !== "User Uploaded Content" && (
          <Row attribute="Content partner">
            <a
              href={`/records?i[content_partner]=${record.displayContentPartner.replace(
                "&",
                "%26"
              )}`}
            >
              {record.displayContentPartner}
            </a>
          </Row>
        )}

        {record.displayCollection !== "User Uploaded Content" && (
          <Row attribute="Collection">
            <a
              href={`/records?i[primary_collection]=${record.displayCollection.replace(
                "&",
                "%26"
              )}`}
            >
              {record.displayCollection}
            </a>
          </Row>
        )}

        {!dnzNil(record.description) && (
          <Row attribute="Description">
            <ReadMore
              maxCharacters={500}
              maxSentences={6}
              contentClass="record-details__description"
            >
              {record.description}
            </ReadMore>
          </Row>
        )}

        <Row attribute="Format">
          {convertArray(
            map(record.category, (category) => {
              return (
                <a href={`/records?tab=${encodeURIComponent(category)}`}>
                  {generateIconTitle([category])}
                </a>
              );
            }),
            " / "
          )}
        </Row>

        {includes(record.tags, "nzresearch") && (
          <Row attribute="Research format">
            {convertArray(
              map(record.dcType, (type) => {
                return (
                  <a href={`/records?i[dc_type]=${encodeURIComponent(type)}&i[tag]=nzresearch`}>
                    {generateIconTitle([type])}
                  </a>
                );
              }),
              " / "
            )}
          </Row>
        )}
        <Row attribute="Thesis level" children={record.thesisLevel} />

        <Row
          attribute="Date created"
          children={formatDate(record.displayDate, "DD/MM/YYYY")}
        />
        <Row attribute="Creator" children={creatorList} />

        {record.displayCollection === "User Uploaded Content" && (
          <Row attribute="Uploaded by">
            {record.belongsToUser
              ? "You"
              : `DigitalNZ user ${record.userImage.user.username}`}
          </Row>
        )}

        {record.displayCollection !== "User Uploaded Content" && (
          <Row
            attribute="Contributing partner"
            children={convertArrayItemsToSpans(record.contributingPartner)}
          />
        )}

        {record.displayCollection !== "User Uploaded Content" && (
          <Row attribute="URL" children={record.contributingPartner}>
            <a
              href={record.sourceUrl}
              className="record-details__link external-link"
              ga-category="Click Through"
              ga-action="Landing page - Url in details"
              ga-label={truncate(record.sourceUrl, { length: 125 })}
              onClick={Analytics.onClick}
            >
              {truncate(record.landingUrl, { length: 100 })}
            </a>
          </Row>
        )}

        <Row
          attribute="Locations"
          children={convertArrayItemsToLinks(record.locations, "i[placename]")}
        />
        <Row
          attribute="Related subjects"
          children={convertArrayItemsToLinks(record.subject, "i[subject]")}
        />
      </dl>
    </div>
  );
};

export { convertArrayItemsToLinks };

export default RecordDetails;
