import { useEffect, useState, useRef } from 'react';
import {
  updateDateRange,
  selectSearch
} from "src/features/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { find } from 'lodash';
import { clearFacet } from '../../features/SearchSlice';
import classNames from "classnames";
import YearPicker from './YearPicker';

const DateRangeFilter = (props) => {
  const dispatch = useDispatch();
  let { filters } = useSelector(selectSearch);
  let { errors }  = useSelector(selectSearch).panel;

  const dateRange = find(filters, (filter) => {
    return filter.facet == 'date_range';
  });

  const fromRef = useRef(null);
  const toRef = useRef(null);

  let fromYear;
  let toYear;

  if(dateRange) {
    const years = dateRange.value.split(' TO ');
    fromYear = years[0];
    toYear = years[1];
  }

  const [fromYearValue, setFromYearValue] = useState(fromYear)
  const [toYearValue, setToYearValue] = useState(toYear)
  const [fromPickerDisplayed, setFromPickerDisplayed] = useState(false);
  const [toPickerDisplayed, setToPickerDisplayed] = useState(false);

  const handleEnterPress = (event) => {
    if(event.keyCode == 13) {
      if(dateRange == undefined) {
        return;
      }

      props.enterCallback();
    }
  };

  useEffect(() => {
    if(dateRange == undefined) {
      setFromYearValue('');
      setToYearValue('');
    }
  }, [dateRange])

  useEffect(() => {
    if(fromYearValue || toYearValue) {
      dispatch(updateDateRange({ facet: 'date_range', value: `${fromYearValue} TO ${toYearValue}`}));
    } else {
      dispatch(clearFacet('date_range'));
    }
    
  }, [fromYearValue, toYearValue, dispatch]);

  const updateYear = (type, year) => {
    setFromPickerDisplayed(false);
    setToPickerDisplayed(false);
    
    if(type == 'from') {
      setFromYearValue(year)
    }

    if(type == 'to') {
      setToYearValue(year)
    }
  }
  
  const toggleYearPicker = (type) => {
    setFromPickerDisplayed(false);
    setToPickerDisplayed(false);

    if(type == 'from') {
      setFromPickerDisplayed(true);
    }

    if(type == 'to') {
      setToPickerDisplayed(true);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {

      if(fromRef.current && !fromRef.current.contains(event.target)) {
        setFromPickerDisplayed(false);
      }

      if(toRef.current && !toRef.current.contains(event.target)) {
        setToPickerDisplayed(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fromRef, toRef]);

  return(
    <div className='cell medium-12 padding-1'>
      <div className='grid-x grid-margin-x'>
        <div className='cell medium-6'>
          <strong>From year</strong>

          <input
            type='text'
            value={fromYearValue}
            maxLength="4"
            className={classNames("cell", "auto", { error: (errors.from || errors.range) })}
            onChange={ (e) => { setFromYearValue(e.target.value.replace(/\D/g,'')) }}
            onFocus={ () => { toggleYearPicker('from') }}
          />

          { fromPickerDisplayed && <YearPicker fromYear={fromYearValue} toYear={toYearValue} type='from' callback={updateYear} ref={fromRef} /> }
          
          { errors.from && (
            <p className="error">
              Please enter a year between 1 and {new Date().getFullYear()}
              <br />
            </p>
          )}

          { errors.range && (
            <p className='error'>
              Must be lower than To year
            </p>
          )}
        </div>

        <div className='cell medium-6'>
          <strong>To year</strong>

          <input
            type='text'
            value={toYearValue}
            placeholder={new Date().getFullYear() }
            maxLength="4"
            className={classNames("cell", "auto", { error: (errors.to || errors.range) })}
            onChange={ (e) => { setToYearValue(e.target.value.replace(/\D/g,'')) }}
            onKeyDown={ (e) => { handleEnterPress(e)} }
            onFocus={ () => { toggleYearPicker('to') }}
          />

          { toPickerDisplayed && <YearPicker fromYear={fromYearValue} toYear={toYearValue} type='to' callback={updateYear} ref={toRef} /> }

          { errors.to && (
            <p className="error">
              Please enter a year between 1 and {new Date().getFullYear()}
            </p>
          )}

          { errors.range && (
            <p className='error'>
              Must be higher than From year
            </p>
          )}
        </div>
      </div>

      <i>This feature is currently in Beta. Please note not all records on DigitalNZ currently have date metadata and may not be shown in your results.</i>
    </div>
  )
}

export default DateRangeFilter;