import { Component } from "react";
import DeleteControls from "src/components/RecordCard/DeleteControls";

function withMyStories(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const { id, name, coverThumbnail } = this.props;

      return (
        <WrappedComponent
          key={id}
          title={name}
          category={["Stories"]}
          link={`/stories/${id}`}
          id={id}
          imageUrl={coverThumbnail}
          children={<DeleteControls {...this.props} />}
        />
      );
    }
  };
}

export default withMyStories;
