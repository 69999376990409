import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearFiltersByFacet,
  selectSearch,
  toggleFacetTab,
} from "src/features/SearchSlice";
import Analytics from "src/utils/Analytics";
import { performSearch } from "src/utils/search";

const FacetFilterActionBar = ({ hasActiveFilters, facetName }) => {
  const search = useSelector(selectSearch);
  const { hasDifferentFilters } = search;
  const dispatch = useDispatch();

  useEffect(() => {
    if (search.refreshWithNewFilters) {
      performSearch(dispatch, search);
    }
  }, [search, dispatch]);

  const clearFilters = (facetName) => {
    Analytics.event("click", "SearchFilterSelect", "Clear " + facetName);
    dispatch(clearFiltersByFacet(facetName));
  };

  const applyFilters = () => {
    Analytics.event("click", "SearchFilterSelect", "Apply filters");
    performSearch(dispatch, search);
  };

  return (
    <div className="search-panel__content__action-bar padding-vertical-1">
      <div className="clear-all">
        {hasActiveFilters && (
          <button
            className="button clear"
            onClick={() => clearFilters(facetName)}
          >
            Clear all
            <i className="fa fa-close end" aria-hidden="true"></i>
          </button>
        )}
      </div>

      {!hasDifferentFilters && (
        <button
          className="button clear"
          onClick={() => dispatch(toggleFacetTab())}
        >
          Close
          <i className="fa fa-close end" aria-hidden="true"></i>
        </button>
      )}

      {hasDifferentFilters && (
        <button className="button" onClick={applyFilters}>
          Apply filters
          <i className="fa fa-chevron-right end" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );
};

export default FacetFilterActionBar;
