export default function generateIconClass(category) {
  category = category[0] || "other";

  if (category.includes("&")) category = category.replace("&", "and");

  // convert category string to lower case
  // split string into array by where there are spaces
  // join array into string using dashes
  category = category.toLowerCase().split(" ").join("-");

  let className = "icon--" + category;
  return className;
}
