import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { editTextBlock } from "src/features/EditStorySlice";

const RichText = ({ id, content, modifiable, activeTextItem }) => {
  const dispatch = useDispatch();

  return (
    <div className="story-blocks__text-container">
      <div dangerouslySetInnerHTML={{ __html: content.value }} />

      {modifiable && (
        <div className="text-center">
          <button
            className="button hollow"
            disabled={!isEmpty(activeTextItem)}
            onClick={() => dispatch(editTextBlock(id))}
          >
            <i className="fa fa-pencil start"></i>
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default RichText;
