/*
  Heavily inspired by: http://alexfedoseev.com/post/64/react-dropdown (@Alex Fedoseev)
   https://github.com/zippyui/react-dropdown-button/blob/master/src/index.jsx (@Zippy Tech)
  Author: Jeffery

*/

import { bindAll, chain, find, includes, isUndefined } from "lodash";
import { Component } from "react";
import CategoryTab from "./CategoryTab";

const PRIMARY_TABS = ["All", "Images", "Audio", "Videos", "Stories"];

class DropDown extends Component {
  constructor(props) {
    super(props);

    // Dropdown block is inactive & hidden by default
    // Local state to track down the open/close, position of dropdown menu
    this.state = {
      dropdownIsActive: false,
      dropdownIsVisible: false,
      categoryStats: props.categoryStats,
    };

    bindAll(
      this,
      "hideDropdown",
      "toggleDropdown",
      "handleClick",
      "handleFocus",
      "handleKeyUp"
    );
  }

  componentDidMount() {
    window.addEventListener("click", this.hideDropdown, false);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.hideDropdown, false);
  }

  selectedStatus(tab) {
    const { dropdownIsVisible } = this.state;
    return !dropdownIsVisible && !includes(PRIMARY_TABS, tab);
  }

  moreTitleCount(tab) {
    if (find(this.props.categoryStats, { category: "More" }).count == 0)
      return 0;

    let tabName = tab;

    if (includes(PRIMARY_TABS, tab)) tabName = "More";

    return find(this.props.categoryStats, { category: tabName }).count;
  }

  toggleDropdown() {
    const { dropdownIsVisible } = this.state;
    this.setState({ dropdownIsVisible: !dropdownIsVisible });
  }

  handleClick(e) {
    this.toggleDropdown();

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  handleKeyUp(e) {
    if (e.key === "Enter") {
      this.toggleDropdown();
    }

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  hideDropdown() {
    this.setState({ dropdownIsVisible: false });
  }

  handleFocus() {
    this.setState({ dropdownIsActive: true });
  }

  getMenuName() {
    const { categoryName, activeCategory } = this.props;

    if (!isUndefined(activeCategory) && !includes(PRIMARY_TABS, activeCategory))
      return activeCategory;
    else return categoryName;
  }

  render() {
    const { categoryStats, activeCategory, dispatch, searchValue, filters } =
      this.props;
    const { dropdownIsVisible } = this.state;

    const activeClass =
      this.selectedStatus(activeCategory) || dropdownIsVisible;
    const disabledTag =
      this.moreTitleCount(activeCategory) === 0
        ? "search-tabs__filter--disabled"
        : "";

    const tabClass = activeClass ? "search-tabs__filter--active" : "";
    const tabMenus = chain(categoryStats)
      .filter((e) => e.category !== "More")
      .map((tab, index) => (
        <CategoryTab
          classNames="search-tabs__filter__more__item"
          categoryName={tab.category}
          count={tab.count}
          filters={filters}
          activeCategory={activeCategory}
          dispatch={dispatch}
          searchValue={searchValue}
          key={index}
        />
      ))
      .value();

    let dropdown = null;
    let dropdownIcon;

    if (dropdownIsVisible) {
      dropdownIcon = "fa fa-chevron-up";
      dropdown = (
        <ul className="search-tabs__filter__more" role="menu">
          {tabMenus}
        </ul>
      );
    } else {
      dropdownIcon = "fa fa-chevron-down";
      dropdown = null;
    }

    return (
      <li
        className={`${tabClass} ${disabledTag} search-tabs__filter search-tabs__filter--dropdown medium-2 columns`}
        onFocus={this.handleFocus}
        onClick={this.handleClick}
        ref="more_dropdown_menu"
      >
        <span
          className="search-tabs__filter__more-button"
          aria-haspopup="true"
          aria-expanded={dropdownIsVisible}
          role="button"
          onKeyUp={this.handleKeyUp}
        >
          {this.getMenuName()}
          <i className={dropdownIcon} aria-hidden="true"></i>
          <div className="search-tabs__filter__count">
            {this.moreTitleCount(activeCategory)}
          </div>
          {dropdown}
        </span>
      </li>
    );
  }
}

export default DropDown;
