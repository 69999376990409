import { join } from "lodash";

const FacetFilter = (props) => {
  const { toggleFacetFilter, isActive, facetFilterName, facetFilterCount } =
    props;

  const facetFilterBaseClass = "search-panel__content__filters__items__item";
  const facetFilterActiveClassModifier = `${facetFilterBaseClass}--active`;
  const isActiveClass = isActive ? facetFilterActiveClassModifier : "";

  return (
    <div className="cell small-12 medium-4">
      <button
        className={join([facetFilterBaseClass, isActiveClass], " ")}
        onClick={toggleFacetFilter}
      >
        <span className="search-panel__content__filters__items__item__title">
          {facetFilterName}
        </span>
        <span className="search-panel__content__filters__items__item__count">
          {" "}
          {facetFilterCount.toLocaleString()}{" "}
        </span>
      </button>
    </div>
  );
};

export default FacetFilter;
