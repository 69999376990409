// This is tested via the DropBlock.spec
// It's also based on the stories redux presenter which is tested with RSpec.

import {
  chunk,
  each,
  first,
  flatten,
  isEmpty,
  isNil,
  map,
  pullAt,
} from "lodash";

import positions from "./positions";
import {
  correctPositions,
  emptyBlock,
  inCorrectPosition,
  largeBlock,
  onTheFarLeft,
  rowEmpty,
  smallBlock,
} from "./utility";

// Converts an array of blocks into the shape that the stories app understands.
// This combines the array of positions together with the approprate blocks, and enforces the rules
// that we have around what blocks can go where. For example it will ensure large blocks are where
// they belong (at the start of a row) and insert the empty blocks into the story data for the frontend.
//
// This logic is essentially the same as in the stories redux presenter, but is here as well to avoid the clientside having to query the rails app
// whenever an item is moved.
//
// == Parameters:
// blocksParam::
//   An array of JSON objects that have been retrieved from the API.
//
// == Returns:
// An array of blocks including all of the empty blocks that the stories app requires.This is then given to the redux state.
//
export default function storyBlocks(blocksParam) {
  let blocksToMove = [...blocksParam];

  let blocks = map(positions(blocksParam), (position) => {
    let block = first(blocksToMove);

    if (isNil(block)) {
      return emptyBlock(position);
    } else if (block.status == "empty") {
      return blocksToMove.shift();
    } else if (smallBlock(block) && inCorrectPosition(block, position)) {
      return blocksToMove.shift();
    } else if (
      largeBlock(block) &&
      inCorrectPosition(block, position) &&
      onTheFarLeft(position)
    ) {
      return blocksToMove.shift();
    } else if (largeBlock(block) && onTheFarLeft(position)) {
      let movedBlock = blocksToMove.shift();
      movedBlock.position = position;

      blocksToMove = map(blocksToMove, (block) => {
        block["position"] += 3;
        return block;
      });

      return movedBlock;
    } else {
      return emptyBlock(position);
    }
  });

  // check if the last row is empty
  let rows = chunk(blocks, 3);
  let rowsToRemove = [];

  each(rows, (row, index) => {
    if (rowEmpty(row)) {
      rowsToRemove.push(index);
    }
  });

  if (!isEmpty(rowsToRemove)) {
    pullAt(rows, rowsToRemove);

    blocks = flatten(rows);
  }

  return correctPositions(blocks);
}
