import generateIconClass from "src/utils/generateIconClass";
import generateIconTitle from "src/utils/generateIconTitle";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";

import { isNil } from "lodash";

const iconThumbnail = (category) => {
  let iconClass = generateIconClass(category);
  let categoryTitle = generateIconTitle(category);

  return (
    <div className="record-card__icon" role="presentation">
      <i className={`icon icon--small ${iconClass}`}></i>
      <p className="record-card__icon__text">{categoryTitle}</p>
    </div>
  );
};

const loadingGifThumbnail = () => {
  return (
    <div className="record-card__link__spinner">
      <i className="fa fa-spinner fa-spin fa-4x fa-fw" aria-hidden="true" />
      <p>Uploading image</p>
    </div>
  );
};

const image = (imageUrl, title) => {
  const escapedTitle = unescapeXMLCharacters(title);
  let imageResizedUrl = new URL(imageUrl);
  imageResizedUrl.searchParams.append("resize", "368%3E");

  return (
    <div className="record-card__image">
      <img
        src={imageResizedUrl}
        alt={`Image: ${escapedTitle}`}
        title={escapedTitle}
      />
    </div>
  );
};

const hasImage = (imageUrl) => {
  return (
    !isNil(imageUrl) &&
    imageUrl.length !== 0 &&
    imageUrl.indexOf("Unknown") === -1
  );
};

const RecordThumbnail = ({ thumbnailImageUrl, imageUrl, title, category }) => {
  let badge;
  if (category.includes("Stories")) {
    badge = (
      <div className="top-triangle-container__label">
        <span className="top-triangle-container__label__content">
          USER STORY
        </span>
      </div>
    );
  }

  return (
    <>
      {badge}
      {category.includes("LoadingGif") ? (
        <>{loadingGifThumbnail()}</>
      ) : (
        <>
          {hasImage(thumbnailImageUrl || imageUrl) &&
            image(thumbnailImageUrl || imageUrl, title)}
          {!hasImage(thumbnailImageUrl || imageUrl) && iconThumbnail(category)}
        </>
      )}
    </>
  );
};

export default RecordThumbnail;
