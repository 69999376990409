import { includes } from "lodash";

export default (tags, toggleS2S) => {
  const featured = includes(tags, "s2s_te_featured");
  const heTohu = includes(tags, "s2s_te_he_tohu");

  return (
    <form className="story-header-edit__content__topic-explorer-form">
      <div className="medium-4 columns">
        <p>Topic Explorer</p>
      </div>
      <div className="medium-4 columns">
        <input
          id="featured-topic-exlorer"
          type="checkbox"
          checked={featured}
          onChange={(e) => toggleS2S(e, "s2s_te_featured")}
        />
        <label>Featured</label>
      </div>
      <div className="medium-4 columns">
        <input
          id="he-tohu-topic-exlorer"
          type="checkbox"
          checked={heTohu}
          onChange={(e) => toggleS2S(e, "s2s_te_he_tohu")}
        />
        <label>He Tohu</label>
      </div>
    </form>
  );
};
