import { createSlice } from "@reduxjs/toolkit";

const facetsSlice = createSlice({
  name: "facets",
  initialState: {},
  reducers: {},
});

export const selectFacets = (state) => state.facets.data;

const { reducer } = facetsSlice;

export default reducer;
