export default function unescapeXMLCharacters(title) {
  const characterEntityMappings = {
    "&quot;": '"',
    "&amp;": "&",
    "&apos;": "'",
    "&lt;": "<",
    "&gt;": ">",
  };

  return title.replace(
    /(&quot;|&lt;|&gt;|&amp;|&apos;)/g,
    function (str, item) {
      return characterEntityMappings[item];
    }
  );
}
