import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {},
});

export const selectUser = (state) => state.entities.user;

const { reducer } = userSlice;

export default reducer;
