import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { snakeToCamelCase } from "src/utils/keyConverters";
import { request } from "src/utils/request";
import { fetchMoreSearchResults } from "./SearchSlice";

export const fetchUserImageRecord = createAsyncThunk(
  "story/fetchUserImageRecordStatus",
  async (userImageId, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/api/user_images/${userImageId}.json`,
      }).then((req) => req.json());
      return snakeToCamelCase(response);
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const submitUserImageEdit = createAsyncThunk(
  "story/submitUserImageEditStatus",
  async (userImage, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/api/user_images/${userImage.id}.json`,
        body: { user_image: userImage },
        options: { method: "PATCH" },
      }).then((req) => req.json());
      return snakeToCamelCase(response);
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const recordsAdapter = createEntityAdapter();

const recordsSlice = createSlice({
  name: "records",
  // These values are overritten by the redux presenter
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserImageRecord.fulfilled, (state, action) => {
        recordsAdapter.setOne(state, action.payload.record);
      })
      .addCase(submitUserImageEdit.fulfilled, (state, action) => {
        recordsAdapter.setOne(state, action.payload);
      })
      .addCase(fetchMoreSearchResults.fulfilled, (state, action) => {
        recordsAdapter.setMany(state, action.payload.records);
      });
  },
});

const { reducer } = recordsSlice;

export const {
  selectById: selectRecordById,
  selectIds: selectRecordIds,
  selectEntities: selectRecordEntities,
  selectAll: selectAllRecords,
  selectTotal: selectTotalRecords,
} = recordsAdapter.getSelectors((state) => state.entities.records);

export default reducer;
